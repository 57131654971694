<template>
  <DetailTemplate
    customClass="visit-detail detail-page"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex">
        <template v-if="pageLoading">
          <v-skeleton-loader
            class="custom-skeleton height-40px custom-skeleton-full-width"
            width="600"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="100"
            type="text"
          >
          </v-skeleton-loader>
          <v-skeleton-loader
            class="custom-skeleton height-30px my-auto mx-2 custom-skeleton-full-width"
            width="130"
            type="text"
          >
          </v-skeleton-loader>
        </template>
        <template v-else>
          <h1 class="form-title custom-nowrap-ellipsis text-capitalize mb-0">
            Visit #{{ visitDetail.barcode }}
          </h1>
          <Priority :priority="detail.priority || 0"></Priority>
          <template v-if="visitDetail.status != 4">
            <CustomStatus
              :status="visitDetail.status || 0"
              endpoint="visit/status"
            ></CustomStatus>
          </template>
        </template>
      </div>
    </template>
    <template v-slot:header-action>
      <div v-if="!pageLoading" class="d-flex justify-end">
        <v-btn
          v-if="visitDetail.status == 4"
          class="mx-2 custom-grey-border custom-bold-button text-white"
          color="light-green darken-1"
        >
          <v-icon left>mdi-check-decagram</v-icon> Completed
        </v-btn>
        <v-btn
          v-if="visitDetail.status != 5 && visitDetail.status != 4"
          v-on:click="startTimerWithLater()"
          :disabled="timerFormLoading"
          :loading="timerFormLoading"
          color="cyan white--text"
          class="mx-2 custom-grey-border custom-bold-button"
        >
          Mark as Complete
        </v-btn>
        <v-menu
          v-if="visitDetail.status != 5"
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-bind="attrs"
              v-on="on"
            >
              More...
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="visitDetail.status == 4"
              v-on:click="routeToInvoice()"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Generate Invoice</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="visitDetail.status == 4"
              v-on:click="downloadPDF()"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Download PDF</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="visitDetail.status == 1"
              v-on:click="appointmentUpdateDialog = true"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Re-Schedule</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="detail.type == 2"
              v-on:click="downloadSchedule()"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Download Schedule</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="visitDetail.status == 1"
              v-on:click="cancelVisitDialog = true"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Cancel Visit</v-list-item-title
              >
            </v-list-item>
            <v-list-item
              v-if="visitDetail.status == 1"
              v-on:click="getAvailableEngineers()"
            >
              <v-list-item-title class="font-weight-600 font-size-16"
                >Get Available Staff</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <template v-if="!pageLoading">
          <template v-if="visitDetail.status == 1">
            <template v-if="engineerList.length">
              <v-menu bottom left offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="mx-2 custom-bold-button white--text"
                    color="cyan"
                    :disabled="apptEngLoading"
                    :loading="apptEngLoading"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon class="mr-2">mdi-account-group</v-icon>Assign
                  </v-btn>
                </template>
                <v-list class="light-gray-background pb-0" min-width="340">
                  <v-list-item-title
                    class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
                  >
                    Select Team
                  </v-list-item-title>
                  <perfect-scrollbar
                    :options="{ suppressScrollX: true }"
                    class="scroll custom-box-top-inner-shadow"
                    style="max-height: 50vh; position: relative"
                  >
                    <v-list-item
                      class="white-background"
                      v-for="(team, index) in engineerList"
                      :key="index"
                    >
                      <v-checkbox
                        v-model="assignedTeam"
                        :label="team.display_name"
                        :value="team.id"
                        hide-details
                        color="cyan"
                        class="mr-4 mt-0"
                        :disabled="apptEngLoading"
                        multiple
                      >
                        <template v-slot:label>
                          <v-list-item>
                            <v-list-item-avatar>
                              <v-img
                                :lazy-src="$defaultProfileImage"
                                :src="$assetAPIURL(team.profile_logo)"
                              ></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-tooltip top content-class="custom-top-tooltip">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-list-item-title
                                    v-bind="attrs"
                                    v-on="on"
                                    class="font-size-16 font-weight-500"
                                    v-html="team.display_name"
                                  ></v-list-item-title>
                                </template>
                                <span class="text-capitalize">
                                  <template v-if="team.skills">{{
                                    team.skills.join(", ")
                                  }}</template>
                                  <template v-else-if="team.designation">{{
                                    team.designation.value
                                  }}</template>
                                  <template v-else><em>No Skills</em></template>
                                </span>
                              </v-tooltip>
                              <v-list-item-subtitle
                                v-if="lodash.isEmpty(team.designation) == false"
                                >{{
                                  team.designation.value
                                }}</v-list-item-subtitle
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-checkbox>
                    </v-list-item>
                  </perfect-scrollbar>
                  <v-btn
                    :disabled="apptEngLoading"
                    :loading="apptEngLoading"
                    v-on:click="updateEngineer()"
                    color="cyan white--text"
                    class="w-100 text-uppercase font-weight-700 font-size-16"
                    >Save</v-btn
                  >
                </v-list>
              </v-menu>
            </template>
          </template>
        </template>
        <v-btn
          class="ml-2 custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="min-height: calc(100vh - 185px); max-height: calc(100vh - 185px); position: relative"
      >
        <v-container>
          <v-row>
            <v-col cols="5" class="pb-0">
              <h3>
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: customer.id,
                      },
                    })
                  "
                  >{{ customer.display_name }}
                </router-link>
              </h3>
              <v-layout class="my-4">
                <v-flex md6 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Service Location</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${property.property_address}`"
                          >{{ property.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="property_contact_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ property_contact_person.primary_email }}
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md6 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Billing Address</td>
                    </tr>
                    <tr>
                      <td class="py-0 font-size-18 font-weight-500">
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${billing.property_address}`"
                          >{{ billing.property_address }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="billing_contact_person.display_name">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.display_name }}
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_phone">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.primary_phone }}
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_email">
                      <td class="py-0 font-size-18 font-weight-500">
                        {{ billing_contact_person.primary_email }}
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
              <v-container v-if="false" fluid>
                <v-list flat>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-account</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                            >
                              <router-link
                                :to="
                                  getDefaultRoute('customer.detail', {
                                    params: {
                                      id: customer.customer || 0,
                                    },
                                  })
                                "
                                >{{ detail.attention }}</router-link
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action
                            link
                            class="m-0 ml-4"
                            v-on:click="
                              $router.push(
                                getDefaultRoute('customer.detail', {
                                  params: {
                                    id: customer.customer || 0,
                                  },
                                })
                              )
                            "
                          >
                            <v-icon class="color-custom-blue"
                              >mdi-link mdi-rotate-135</v-icon
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                      <span>Attention</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-email</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                            >
                              <a
                                :href="'mailto:' + contactPerson.primary_email"
                                >{{ contactPerson.primary_email }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Contact Email</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else>
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item
                          class="py-1 max-content-width"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28">mdi-phone</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                            >
                              <a :href="'tel:' + contactPerson.primary_phone">{{
                                contactPerson.primary_phone
                              }}</a>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Contact Phone</span>
                    </v-tooltip>
                  </template>
                  <v-skeleton-loader
                    class="custom-skeleton height-40px custom-skeleton-full-width"
                    type="text"
                    v-if="pageLoading"
                  >
                  </v-skeleton-loader>
                  <template v-else-if="property && property.property_address">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item class="py-1" v-bind="attrs" v-on="on">
                          <v-list-item-icon class="margin-auto-zero mr-4">
                            <v-icon class="font-size-28"
                              >mdi-home-map-marker</v-icon
                            >
                          </v-list-item-icon>
                          <v-list-item-content class="p-0">
                            <v-list-item-title
                              class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                            >
                              <a
                                target="_blank"
                                :href="
                                  'https://maps.google.com/?q=' +
                                  property.property_address
                                "
                                >{{ property.property_address }}</a
                              >
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <span>Service Location</span>
                    </v-tooltip>
                  </template>
                  <template v-if="billing && billing.property_address">
                    <v-skeleton-loader
                      class="custom-skeleton height-40px custom-skeleton-full-width"
                      type="text"
                      v-if="pageLoading"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-list-item class="py-1" v-bind="attrs" v-on="on">
                            <v-list-item-icon class="margin-auto-zero mr-4">
                              <v-icon class="font-size-28"
                                >mdi-home-currency-usd</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content class="p-0">
                              <v-list-item-title
                                class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                              >
                                <a
                                  target="_blank"
                                  :href="
                                    'https://maps.google.com/?q=' +
                                    billing.property_address
                                  "
                                  >{{ billing.property_address }}</a
                                >
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                        <span>Billing Address</span>
                      </v-tooltip>
                    </template>
                  </template>
                </v-list>
                <template v-if="pageLoading">
                  <div class="d-flex">
                    <v-skeleton-loader
                      v-for="(d, i) in 3"
                      class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                      width="100"
                      type="text"
                      :key="i"
                    >
                    </v-skeleton-loader>
                  </div>
                </template>
                <template v-else>
                  <template v-for="(type, index) in detail.ticket_type">
                    <v-tooltip
                      :key="'ticket-type' + index"
                      top
                      content-class="custom-top-tooltip"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip
                          v-bind="attrs"
                          v-on="on"
                          class="ma-2 font-weight-600"
                          color="chip-custom-blue"
                          label
                          outlined
                        >
                          {{ type }}
                        </v-chip>
                      </template>
                      <span>Job Type</span>
                    </v-tooltip>
                  </template>
                </template>
              </v-container>
            </v-col>
            <v-col md="7" class="pb-0 gray-background pl-4">
              <v-container fluid>
                <v-row dense>
                  <v-col md="12">
                    <v-skeleton-loader
                      class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                      type="text"
                      v-if="pageLoading"
                    >
                    </v-skeleton-loader>
                    <p
                      v-else
                      class="m-0 font-weight-700 font-size-18 pt-3 pb-1 color-custom-blue"
                      v-on:dblclick="updateField('title')"
                    >
                      <template v-if="updateDetailDialog.title">
                        <v-text-field
                          ref="title"
                          v-model.trim="updateDetail.title"
                          dense
                          filled
                          color="cyan"
                          label="Visit Title"
                          :rules="[
                            validateRules.minLength(
                              updateDetail.title,
                              'Visit Title',
                              1
                            ),
                            validateRules.maxLength(
                              updateDetail.title,
                              'Visit Title',
                              100
                            ),
                          ]"
                          solo
                          flat
                          hide-details
                          :loading="particuleFieldLoading"
                          :disabled="particuleFieldLoading"
                          @keydown.esc="onEscParticularField"
                          @keydown.enter="updateParticularField"
                        ></v-text-field>
                      </template>
                      <template v-else>
                        <span
                          :class="{
                            'custom-bottom-border':
                              getPermission('visit:update'),
                          }"
                          >{{ visitDetail.title }}</span
                        >
                        <UpdateTooltip v-if="canUpdateVisit"></UpdateTooltip>
                      </template>
                    </p>
                    <template v-if="false">
                      <v-skeleton-loader
                        class="custom-skeleton my-auto custom-skeleton-full-width mr-5"
                        type="sentences"
                        v-if="pageLoading"
                      >
                      </v-skeleton-loader>
                      <template v-else>
                        <template v-if="updateDetailDialog.instruction">
                          <v-textarea
                            ref="instruction"
                            auto-grow
                            v-model.trim="updateDetail.instruction"
                            dense
                            filled
                            color="cyan"
                            label="Instruction"
                            :rules="[
                              validateRules.minLength(
                                updateDetail.instruction,
                                'Description',
                                1
                              ),
                              validateRules.maxLength(
                                updateDetail.instruction,
                                'Description',
                                1024
                              ),
                            ]"
                            solo
                            flat
                            hide-details
                            row-height="25"
                            :loading="particuleFieldLoading"
                            :disabled="particuleFieldLoading"
                            @keydown.esc="onEscParticularField"
                            @keydown.enter="updateParticularField"
                          ></v-textarea>
                        </template>
                        <template v-else>
                          <div
                            class="d-flex align-start"
                            v-on:dblclick="updateField('instruction')"
                          >
                            <read-more
                              class="custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              :class="{
                                'custom-bottom-border':
                                  getPermission('visit:update'),
                              }"
                              more-str="read more"
                              :text="
                                visitDetail.instruction ||
                                '<em>No Instructions</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                            <UpdateTooltip
                              v-if="canUpdateVisit"
                            ></UpdateTooltip>
                          </div>
                        </template>
                      </template>
                    </template>
                  </v-col>
                  <v-col md="12">
                    <v-skeleton-loader
                      class="custom-skeleton height-30px mr-2 my-auto custom-skeleton-full-width"
                      width="100"
                      type="text"
                      v-if="pageLoading"
                    >
                    </v-skeleton-loader>
                    <v-chip
                      v-else
                      class="my-2 font-weight-600"
                      color="chip-custom-blue"
                      label
                      outlined
                    >
                      {{ getScheduleType }}
                    </v-chip>
                    <template v-if="pageLoading">
                      <v-skeleton-loader
                        v-for="(d, i) in 2"
                        :key="i"
                        class="custom-skeleton height-40px custom-skeleton-full-width mr-5"
                        :class="{ 'mt-3': i <= 0, 'my-1': i > 0 }"
                        type="text"
                      >
                      </v-skeleton-loader>
                    </template>
                    <template v-else>
                      <table class="width-100">
                        <tr v-if="false">
                          <td class="font-size-18 pt-3 pb-1" width="200">
                            Job number
                          </td>
                          <td class="font-weight-600 font-size-18 pt-3 pb-1">
                            {{ detail.barcode }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-18 py-1" width="200">
                            Visit number
                          </td>
                          <td class="font-weight-600 font-size-18 py-1">
                            {{ visitDetail.barcode }}
                          </td>
                        </tr>
                        <tr v-if="detail.po_number">
                          <td class="font-size-18 py-1" width="200">
                            PO Number
                          </td>
                          <td class="font-weight-600 font-size-18 py-1">
                            {{ detail.po_number }}
                          </td>
                        </tr>
                        <tr>
                          <td class="font-size-18 py-1" width="200">
                            Appointment
                          </td>
                          <td class="font-weight-600 font-size-18 py-1">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <a
                                  v-bind="attrs"
                                  v-on="on"
                                  href="javascript:void(0)"
                                  v-on:click="downloadVisit"
                                  class="text-uppercase max-content-width"
                                  >{{ getAppointmentText }}</a
                                >
                              </template>
                              <span>Click here to download Appointment</span>
                            </v-tooltip>
                            <v-tooltip
                              top
                              content-class="custom-top-tooltip"
                              v-if="visitDetail.status != 4"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :disabled="isTimmerRunning"
                                  icon
                                  text
                                  class="mx-2"
                                  v-on:click="appointmentUpdateDialog = true"
                                  ><v-icon v-bind="attrs" v-on="on" color="cyan"
                                    >mdi-clock-fast</v-icon
                                  ></v-btn
                                >
                              </template>
                              <span>Click here to reschedule Appointment</span>
                            </v-tooltip>
                          </td>
                        </tr>
                        <tr v-if="getVisitScheduleType()">
                          <td class="font-size-18 py-1" width="200">
                            Schedule
                          </td>
                          <td class="font-weight-600 font-size-18 py-1">
                            <read-more
                              class="custom-read-more font-weight-600 font-size-16 pb-1 pr-2 custom-gray-color"
                              more-str="read more"
                              :text="getVisitScheduleType()"
                              link="#"
                              less-str="read less"
                              :max-chars="60"
                            >
                            </read-more>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col>
              <v-tabs
                v-model="visitTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#line-item'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Item
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  href="#staff"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Staff
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#service-form'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/service-form.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Service Form
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#time-sheet'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/timesheet.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Timesheet
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#close-detail'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/closer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Closer
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#reminder'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/billing.svg')"
                    />
                    <!--end::Svg Icon--> </span
                  >Reminder
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#notes'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/note.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Notes
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8 color-custom-blue"
                  :href="'#history'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
              </v-tabs>
              <v-tabs-items class="visit-dialog-tab" v-model="visitTab">
                <v-tab-item value="line-item">
                  <LineItemNew
                    show-detail
                    :can-update="canUpdateVisit"
                    :loading="lineItemLoading"
                    :db-line-items="dbLineItems"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :tax-value="detail.tax_value"
                    :adjustment-value="detail.adjustment"
                    v-on:update:line-item="updateLineItem($event)"
                    v-on:update:line-item-calculation="
                      updateLineItemCalculation($event)
                    "
                  ></LineItemNew>
                </v-tab-item>
                <v-tab-item value="staff">
                  <VisitTeamDetail
                    v-if="visitDetail.ticket"
                    :detail="{ id: visitDetail.ticket.id }"
                    :visit-id="visitDetail.id"
                    v-on:delete:engineer="loadContent()"
                  ></VisitTeamDetail>
                </v-tab-item>
                <v-tab-item v-if="false" :value="'service-form'">
                  <ServiceFormDetail
                    :detail="detail"
                    :visit="visitDetail"
                    :customer="customer"
                    :property="property"
                    :billing="billing"
                    :contactPerson="contactPerson"
                    :pageLoading="pageLoading"
                    isVisitDetail
                  ></ServiceFormDetail>
                </v-tab-item>
                <v-tab-item v-if="false" :value="'time-sheet'">
                  <TimeTrackingDetail
                    :detail="detail"
                    :visitDetail="visitDetail"
                    :customer="customer"
                    :property="property"
                    :billing="billing"
                    :contactPerson="contactPerson"
                    :pageLoading="pageLoading"
                    isVisitDetail
                  ></TimeTrackingDetail>
                </v-tab-item>
                <v-tab-item :value="'close-detail'">
                  <TicketCloserDetail
                    isVisitDetail
                    :visitDetail="visitDetail"
                    :detail="detail"
                    :customer="customer"
                    :property="property"
                    :billing="billing"
                    :contactPerson="contactPerson"
                    :pageLoading="pageLoading"
                  ></TicketCloserDetail>
                </v-tab-item>
                <v-tab-item :value="'reminder'">
                  <TicketVisitReminder
                    isVisitDetail
                    :visitDetail="visitDetail"
                    :detail="detail"
                    :customer="customer"
                    :property="property"
                    :billing="billing"
                    :contactPerson="contactPerson"
                    :pageLoading="pageLoading"
                  ></TicketVisitReminder>
                </v-tab-item>
                <v-tab-item :value="'notes'">
                  <VisitNotesDetail
                    :detail="detail"
                    :visit="visitDetail"
                    :notes="visitNotes"
                    :pageLoading="pageLoading"
                  >
                  </VisitNotesDetail>
                </v-tab-item>
                <v-tab-item :value="'history'">
                  <InternalHistoryDetail
                    type="visit"
                    :type_id="visitDetail.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <Dialog :commonDialog="timerDialog" :dialogWidth="dialogWidth">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="pt-0">
            <v-row>
              <v-col md="12" class="pt-0">
                <p class="font-weight-600 font-size-18 color-custom-blue">
                  Are you sure, you want to stop timer ?
                </p>
                <p class="font-weight-500 custom-border-top pt-4">Remarks</p>
                <v-textarea
                  v-model.trim="timer_remarks"
                  auto-grow
                  dense
                  filled
                  flat
                  label="Remarks..."
                  solo
                  color="cyan"
                  hide-details
                  row-height="20"
                ></v-textarea>
              </v-col>
              <v-col md="12" v-if="$haveRecordAudio">
                <p class="font-weight-500">Record Audio</p>
                <RecordAudio
                  v-model="audioDataURL"
                  v-on:start-recording="recordingStartedUpdate"
                  v-on:stop-recording="recordingStoppedUpdate"
                ></RecordAudio>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="stopRunningTimer"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="formLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="timerDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="completeDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue">
                  Are you sure, you want to mark as complete visit#
                  {{ visitDetail.barcode }} ?
                </p>
                <em class="font-size-14"
                  >Note: this action will force stop all running timers.</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="markAsComplete"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="formLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="completeDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="allTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue">
                  Are you sure, you want to stop all running timers of visit#
                  {{ visitDetail.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="stopAllTimers"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="formLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="allTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="resumeTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue my-0">
                  Are you sure, you want to resume timer for visit#
                  {{ visitDetail.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || pageLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="resumeTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="resumeTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="startTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    Are you sure, you want to start timer for visit#
                    {{ visitDetail.barcode }} ?
                  </p>
                </v-col>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || pageLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimerWithLater"
          >
            Assign Later
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || pageLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="startTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :commonDialog="assignServiceFormDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="assignServiceForm"
            v-model.trim="assignFormValid"
            lazy-validation
            v-on:submit.stop.prevent="assignServiceForm"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="assignFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="assignFormLoading || !assignFormValid || pageLoading"
            :loading="assignFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="assignServiceForm"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="assignFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="assignServiceFormDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <!-- Any timesheet is running of current user -->
      <Dialog :commonDialog="timesheetRunningDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue">
                  Below are running timesheets which are not closed till yet.
                </p>
                <table class="width-100">
                  <tbody>
                    <tr
                      v-for="(timer, index) in userRunningTimers"
                      :key="index"
                    >
                      <td><Barcode :barcode="timer.barcode"></Barcode></td>
                      <td>
                        Started at
                        {{ formatDateTime(timer.date + " " + timer.start_at) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="formLoading || pageLoading || timerLoading"
            :loading="formLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="timesheetRunningClose"
          >
            Close all &amp; continue
          </v-btn>
          <v-btn
            :loading="timerLoading"
            :disabled="formLoading || pageLoading || timerLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="getServiceFormList"
          >
            Continue
          </v-btn>
        </template>
      </Dialog>
      <!-- Reschedule Appointment -->
      <Dialog :commonDialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
              <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
              <v-col md="4" class="py-1 font-weight-600"> End Time </v-col>
              <v-col md="4" class="py-1">
                <Datepicker
                  :defaultDate="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                ></Datepicker>
              </v-col>
              <v-col md="4" class="py-1">
                <Timepicker
                  :defaultTime="updateStartTime"
                  solo
                  v-on:update:time-picker="updateAppointmentStartTime"
                ></Timepicker>
              </v-col>
              <v-col md="4" class="py-1">
                <Timepicker
                  :defaultTime="updateEndTime"
                  solo
                  v-on:update:time-picker="updateAppointmentEndTime"
                ></Timepicker>
              </v-col>
              <v-col md="12" class="py-2" v-if="false">
                <em
                  >All engineers will be removed from visit#
                  {{ visitDetail.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
      <!-- Re Assign Appointment -->
      <Dialog :commonDialog="engineerDialog">
        <template v-slot:title>Assign Staff</template>
        <template v-slot:body>
          <v-container>
            <v-row>
              <v-col md="12">
                <v-autocomplete
                  dense
                  color="cyan"
                  filled
                  :items="engineerList"
                  v-model.trim="visitEngineer"
                  label="Staff"
                  solo
                  flat
                  item-color="cyan"
                  item-text="display_name"
                  item-value="id"
                  hide-details
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Staff(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-img
                        :lazy-src="$defaultProfileImage"
                        :src="$assetAPIURL(item.profile_logo)"
                        aspect-ratio="1"
                        contain
                        class="margin-auto grey lighten-5 custom-grey-border"
                        transition="fade-transition"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >#{{ item.barcode }}</v-list-item-title
                      >
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.display_name }}</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-action class="align-self-center">
                      <v-list-item-subtitle
                        class="text-lowercase pb-2 font-weight-500 font-size-14"
                        >{{ item.primary_email }}</v-list-item-subtitle
                      >
                      <v-list-item-subtitle
                        class="text-lowercase font-weight-500 font-size-14"
                        >{{ item.primary_phone }}</v-list-item-subtitle
                      >
                    </v-list-item-action>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptEngLoading || pageLoading"
            :loading="apptEngLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateEngineer()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptEngLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="engineerDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <DeleteDialog :delete-dialog="cancelVisitDialog">
        <template v-slot:title>Cancel Confirmation</template>
        <template v-slot:text>
          <h3 class="font-size-16 font-weight-500 delete-text">
            Once you cancel
            <span class="font-weight-700">{{ visitDetail.barcode }}</span
            >, you won't be able to revert this later.<br />Are you sure you
            want to cancel
            <span class="font-weight-700">{{ visitDetail.barcode }}</span>
            ?
          </h3>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="cancelVisitDialog = false"
            >No! Cancel</v-btn
          >
          <v-btn
            color="red lighten-1 white--text"
            v-on:click="cancelVisitConfirm()"
            class="mx-2 custom-grey-border custom-bold-button"
            >Yes! Cancel</v-btn
          >
        </template>
      </DeleteDialog>

      <!-- Create Closer -->
      <template v-if="getPermission('closer:create') && closerDialog">
        <CreateTimeCloser
          isVisitDetail
          :timesheet="runningTimer.id"
          :visitDetail="visitDetail"
          :closerDialog="closerDialog"
          :detail="detail"
          v-on:create:ticket-closer="createdTimeCloser"
          v-on:close-dialog="closerDialog = false"
        ></CreateTimeCloser>
      </template>
      <!-- Fill Service Form -->
      <template
        v-if="getPermission('service-form:update') && updateServiceFormDialog"
      >
        <UpdateServiceForm
          :detail="detail"
          :customer="customer"
          :property="property"
          :billing="billing"
          :contactPerson="contactPerson"
          :serviceForm="runningTimer.service_form"
          :detailDialog="updateServiceFormDialog"
          v-on:update:service-form="updatedServiceForm"
          v-on:close:dialog="closerServiceFormDialog"
        ></UpdateServiceForm>
      </template>
    </template>
  </DetailTemplate>
</template>

<script>
import moment from "moment-timezone";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import VisitNotesDetail from "@/view/pages/job/partials/Visit-Notes-Detail.vue";
import {
  GET,
  QUERY,
  PATCH,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import { downloadEvent } from "@/core/lib/ics.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Priority from "@/view/pages/partials/Priority.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import VisitTeamDetail from "@/view/pages/job/partials/Visit-Team-Detail.vue";
import TimeTrackingDetail from "@/view/pages/job/partials/Time-Tracking-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import TicketVisitReminder from "@/view/pages/job/partials/Ticket-Visit-Reminder.vue";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DeleteDialog from "@/view/pages/partials/Delete.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import UpdateTooltip from "@/view/pages/partials/UpdateTooltip.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import Timepicker from "@/view/pages/partials/Timepicker.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import UpdateServiceForm from "@/view/pages/job/partials/Update-Service-Form.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import RecordAudio from "@/view/pages/partials/RecordAudio";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import JwtService from "@/core/services/jwt.service";
import { toNumber } from "lodash";
import LineItemNew from "@/view/pages/partials/Line-Item-New";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  data() {
    return {
      resumeTimerDialog: false,
      assignFormLoading: false,
      assignFormValid: true,
      appointmentUpdateDialog: false,
      timesheetRunningDialog: false,
      apptFormLoading: false,
      allowUpdate: false,
      timerLoading: true,
      timerDialog: false,
      closerDialog: false,
      startTimerDialog: false,
      completeDialog: false,
      updateServiceFormDialog: false,
      assignServiceFormDialog: false,
      allTimerDialog: false,
      timer_remarks: null,
      audioDataURL: null,
      timerFormValid: true,
      timerFormLoading: false,
      recordingStarted: false,
      recordingStopped: false,
      loadingEngineers: false,
      engineerDialog: false,
      apptEngLoading: false,
      runningTimer: new Object(),
      isTimmerRunning: false,
      pageLoading: true,
      detail: new Object(),
      visitDetail: new Object({
        can_update: false,
      }),
      customer: new Object(),
      contactPerson: new Object(),
      property: new Object(),
      property_contact_person: new Object(),
      billing_contact_person: new Object(),
      billing: new Object(),
      visitNotes: new Object(),
      // visitTab: null,
      visit: 0,
      particuleFieldLoading: false,
      updateDetailDialog: {
        title: false,
        instruction: false,
      },
      updateDetail: {
        title: null,
        instruction: null,
      },
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_time: null,
      },
      serviceFormList: new Array(),
      engineerList: new Array(),
      visitEngineer: null,
      userRunningTimers: new Array(),
      timeInterval: null,
      durationInterval: null,
      timerServiceForm: new Array(),
      timeIntervalLimit: 10000,
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      closer: {
        pdf_url: null,
      },
      assignedTeam: [],
      dbLineItems: [],
      newLineItems: [],
      cancelVisitDialog: false,
      cancelVisitLoading: false,
      cancelVisitBarcode: null,
      lineItemLoading: false,
    };
  },
  methods: {
    updateLineItem(rows) {
      this.newLineItems = rows;
      this.$nextTick(() => {
        this.updateLineItems();
      });
    },
    updateLineItemCalculation(row) {
      const formData = new Object({
        tax_applied: row.apply_tax,
        discount_type: 1,
        discount_value_type: row.discount_value_type,
        discount_value: row.discount_value,
        adjustment: row.adjustment,
      });

      this.$nextTick(() => {
        this.$store
          .dispatch(PATCH, {
            url: "job/" + this.visitDetail.ticket.id,
            data: formData,
          })
          .then(() => {
            this.loadContent();
          })
          .catch((error) => {
            this.logError(error);
          });
      });
    },
    updateLineItems() {
      this.lineItemLoading = true;
      this.CreateLineItems({
        type: "ticket",
        parent: this.visitDetail.ticket.id,
        visit: this.visitDetail.id,
        formData: this.newLineItems,
      })
        .then(() => {
          this.getLineItems({
            job: this.visitDetail.ticket.id,
            visit: this.visitDetail.id,
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.lineItemLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item",
          data,
        })
        .then(({ data }) => {
          if (
            _this.lodash.isEmpty(data.rows) === false &&
            _this.lodash.isArray(data.rows)
          ) {
            let result = [];

            for (let i = 0; i < data.rows.length; i++) {
              result.push({
                id: data.rows[i].id,
                visit_barcode: data.rows[i].visit
                  ? data.rows[i].visit.barcode
                  : null,
                group: data.rows[i].group_me,
                group_primary: data.rows[i].group_primary,
                product: data.rows[i].product.name,
                product_id: data.rows[i].product.product,
                product_type: data.rows[i].product.product_type,
                description: data.rows[i].description,
                rate: data.rows[i].rate,
                quantity: data.rows[i].quantity,
                uom:
                  data.rows[i].product && data.rows[i].product.unit
                    ? data.rows[i].product.unit.text
                    : null,
              });
            }

            _this.dbLineItems = result;
          }
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        });
    },
    cancelVisitConfirm() {
      this.cancelVisitLoading = true;
      this.$store
        .dispatch(PUT, { url: `visit/${this.visitDetail.id}/mark-as-cancel` })
        .then(() => {
          this.loadContent();
          this.cancelVisitDialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.cancelVisitLoading = false;
        });
    },
    downloadSchedule() {
      const ticketId = this.visitDetail.ticket.id;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${ticketId}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    updateEngineer() {
      const visitId = this.visitDetail.id;
      const ticketId = this.visitDetail.ticket.id;
      const _this = this;
      if (!_this.assignedTeam.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      }
      _this.apptEngLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "job/" + ticketId + "/visit/" + visitId + "/engineer",
          data: {
            engineer: _this.assignedTeam,
          },
        })
        .then(() => {
          _this.engineerList = [];
          _this.$nextTick(() => {
            _this.loadContent();
          });
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.apptEngLoading = false;
        });
    },
    getAvailableEngineers() {
      const _this = this;
      _this.loadingEngineers = true;
      _this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: moment(_this.visitDetail.started_at).format(
              "YYYY-MM-DD"
            ),
            start_time: moment(_this.visitDetail.started_at).format("HH:mm"),
            end_date: moment(_this.visitDetail.finished_at).format(
              "YYYY-MM-DD"
            ),
            end_time: moment(_this.visitDetail.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
          },
        })
        .then(({ data }) => {
          _this.engineerList = data.engineers;
          // _this.engineerDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadingEngineers = false;
        });
    },
    downloadPDF() {
      if (this.closer.pdf_url) {
        window.open(this.closer.pdf_url, "_blank");
      } else {
        const token = JwtService.getToken();
        const url = `${this.$apiURL}visit/${this.visitDetail.id}/closer/${this.closer.id}/download?token=${token}`;
        window.open(url, "_blank");
      }
    },
    routeToInvoice() {
      const { customer } = this.customer;
      const { id } = this.visitDetail;
      const { ticket } = this.visitDetail;

      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            customer,
            property: this.property.id,
            billing: this.billing.id,
            visit: id,
            job: ticket.id,
          },
        })
      );
    },
    closerServiceFormDialog() {
      const _this = this;
      _this.updateServiceFormDialog = false;
      _this.getActiveTimer();
      _this.loadContent();
    },
    assignServiceForm() {
      const _this = this;

      if (!_this.$refs.assignServiceForm.validate()) {
        return false;
      }

      _this.assignFormLoading = true;

      _this.$store
        .dispatch(POST, {
          url: `job/${_this.detail.id}/visit/${_this.visit}/service-form/${_this.timerServiceForm}`,
          data: {
            timesheet: _this.runningTimer.id || null,
          },
        })
        .then(() => {
          _this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.assignFormLoading = false;
          _this.assignServiceFormDialog = false;
          _this.getActiveTimer();
        });
    },
    assignServiceFormDetail() {
      const _this = this;
      _this.timerLoading = true;

      _this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            timesheet: _this.runningTimer.id,
            customer: _this.customer.customer,
          },
        })
        .then(({ data }) => {
          _this.serviceFormList = data;
          _this.assignServiceFormDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
        });
    },
    createdTimeCloser() {
      this.closerDialog = false;
      this.getActiveTimer();
      this.loadContent();
    },
    updatedServiceForm() {
      this.updateServiceFormDialog = false;
      this.getActiveTimer();
    },
    timesheetRunningClose() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, { url: "running-time-sheet" })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          _this.getServiceFormList();
        });
    },
    checkRunningTimerStatus() {
      const _this = this;
      _this.timerLoading = true;

      _this.$store
        .dispatch(GET, { url: "running-time-sheet" })
        .then(({ data }) => {
          if (_this.lodash.isEmpty(data) === false) {
            _this.userRunningTimers = data;
            _this.timesheetRunningDialog = true;
          } else {
            _this.getServiceFormList();
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
        });
    },
    getServiceFormList() {
      const _this = this;
      _this.timerLoading = true;
      _this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            customer: _this.customer.customer,
          },
        })
        .then(({ data }) => {
          _this.serviceFormList = data;
          _this.startTimerDialog = true;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerLoading = false;
          _this.timesheetRunningDialog = false;
        });
    },
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentStartTime(param) {
      this.updateAppointmentFormData.start_time = param;
    },
    updateAppointmentEndTime(param) {
      this.updateAppointmentFormData.end_time = param;
    },
    updateAppointment() {
      const _this = this;
      _this.apptFormLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.visit + "/re-schedule",
          data: {
            date: _this.updateAppointmentFormData.date
              ? moment(_this.updateAppointmentFormData.date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),
            start_time: _this.updateAppointmentFormData.start_time
              ? moment(_this.updateAppointmentFormData.start_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().startOf("day").format("HH:mm"),
            end_time: _this.updateAppointmentFormData.end_time
              ? moment(_this.updateAppointmentFormData.end_time, [
                  "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
          },
        })
        .then(() => {
          _this.appointmentUpdateDialog = false;
          _this.$router.push(_this.getDefaultRoute("visit"));
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.apptFormLoading = false;
        });
    },
    startTimer() {
      const _this = this;
      if (!_this.$refs.timerStartForm.validate()) {
        return false;
      }

      _this.timerFormLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" + _this.detail.id + "/visit/" + _this.visit + "/timer/start",
          data: {
            detail: _this.timer_remarks,
            service_form: _this.timerServiceForm,
          },
        })
        .then(() => {
          _this.startTimerDialog = false;
          _this.timerServiceForm = new Array();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerFormLoading = false;
          _this.getActiveTimer();
          _this.loadContent();
        });
    },
    startTimerWithLater() {
      const _this = this;
      if (_this.isTimmerRunning) {
        _this.closerDialog = true;
      } else {
        _this.timerFormLoading = true;
        _this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              _this.detail.id +
              "/visit/" +
              _this.visit +
              "/timer/start",
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.timerFormLoading = false;
            _this.getActiveTimer(true);
            _this.loadContent();
          });
      }
    },
    setTimerDuration() {
      const _this = this;
      if (_this.lodash.isEmpty(_this.runningTimer) === false) {
        let startDate = _this.lodash.toString(_this.runningTimer.date);
        if (_this.runningTimer.start_at) {
          startDate = _this.lodash.toString(
            startDate + " " + _this.runningTimer.start_at
          );
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          _this.timerDuration = {
            hour: hours > 0 ? _this.lodash.padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? _this.lodash.padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? _this.lodash.padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    recordingStartedUpdate(param) {
      this.recordingStarted = param;
    },
    recordingStoppedUpdate(param) {
      this.recordingStopped = param;
    },
    stopRunningTimer() {
      const _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.runningTimer.ticket +
            "/visit/" +
            _this.runningTimer.visit +
            "/timer/" +
            _this.runningTimer.id +
            "/end",
          data: { detail: _this.timer_remarks, audio: _this.audioDataURL },
        })
        .then(() => {
          _this.timerDialog = false;
          _this.timer_remarks = null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
          _this.getActiveTimer();
          _this.loadContent();
        });
    },
    markAsComplete() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url:
            "job/" +
            _this.detail.id +
            "/visit/" +
            _this.visit +
            "/update-status/1",
        })
        .then(() => {
          _this.loadContent();
          _this.completeDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    stopAllTimers() {
      const _this = this;
      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "visit/" + _this.visit + "/stop-all-timer",
        })
        .then(() => {
          _this.loadContent();
          _this.allTimerDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
        _this.getActiveTimer,
        _this.timeIntervalLimit
      );
    },
    getActiveTimer(closer) {
      const _this = this;
      if (_this.visitDetail.status < 4) {
        _this.timerLoading = true;
        _this.$store
          .dispatch(GET, {
            url: "visit/" + _this.visit + "/running",
          })
          .then(({ data }) => {
            _this.runningTimer = new Object(data);
            _this.isTimmerRunning = !!data;
            if (_this.isTimmerRunning) {
              _this.resumeTimerDialog = false;
            }
            if (closer) {
              _this.$nextTick(() => {
                _this.closerDialog = true;
              });
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.timerLoading = false;
          });
      }
    },
    updateField(type) {
      const _this = this;
      if (_this.canUpdateVisit) {
        if (!_this.allowUpdate) {
          return false;
        }
        _this.onEscParticularField();
        _this.updateDetailDialog[type] = true;
        _this.updateDetail[type] = _this.visitDetail[type];
        setTimeout(function () {
          _this.$refs[type].focus();
        }, 50);
      }
    },
    onEscParticularField() {
      this.updateDetailDialog = {
        title: false,
        instruction: false,
      };
      this.updateDetail = {
        title: null,
        instruction: null,
      };
    },
    patchVisit(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(PATCH, {
              url: "visit/" + _this.visit,
              data,
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateParticularField() {
      const _this = this;
      _this.particuleFieldLoading = true;
      if (_this.canUpdateVisit) {
        _this
          .patchVisit(_this.updateDetail)
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.loadContent();
            _this.particuleFieldLoading = false;
            _this.onEscParticularField();
          });
      }
    },
    resumeTimer() {
      const _this = this;
      _this.timerFormLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "visit/" + _this.visit + "/resume" })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.timerFormLoading = false;
          _this.getActiveTimer();
          _this.loadContent();
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visitDetail.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visitDetail.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visitDetail.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visitDetail.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: this.lodash.concat(startDateArray, startTimeArray),
          end: this.lodash.concat(endDateArray, endTimeArray),
          title: this.visitDetail.title,
          description: this.visitDetail.instruction,
          location: this.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.property.latitude),
            lon: toNumber(this.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.detail.added_by.display_name,
            email: this.detail.added_by.user_email,
          },
          attendees: this.visitDetail.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({ error });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    getVisitScheduleType() {
      return this.detail ? this.detail.schedule_message : "";
    },
    loadContent() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "visit/" + _this.visit,
        })
        .then(({ data }) => {
          _this.getLineItems({ job: data.ticket.id, visit: data.id });
          _this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: "Visit",
            },
            {
              title: "Detail",
            },
            {
              title: data.barcode,
            },
          ]);
          _this.visitDetail = data;
          _this.closer = data.closer;
          _this.detail = data.ticket;
          _this.customer = data.customer;
          _this.contactPerson = data.contact_person || new Object();
          _this.property = data.property || new Object();
          _this.property_contact_person =
            data.property_contact_person || new Object();
          _this.billing_contact_person =
            data.billing_contact_person || new Object();
          _this.billing = data.billing || new Object();
          _this.visitNotes = data.notes;
          _this.allowUpdate = true;
          if (_this.visitDetail.status === 4) {
            _this.allowUpdate = false;
            clearInterval(_this.timeInterval);
            clearInterval(_this.durationInterval);
          }

          if (
            _this.visitDetail.engineers &&
            _this.visitDetail.engineers.length
          ) {
            _this.assignedTeam = _this.visitDetail.engineers.map(
              (row) => row.engineer
            );
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getActiveTimer();
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    const _this = this;

    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Visit",
      },
      {
        title: "Detail",
      },
    ]);
    _this.loadContent();
    // _this.checkTimerStatus();
    // _this.updateTimerDuration();

    ServiceFormEventBus.$on("save:service-form", function () {
      _this.getActiveTimer();
    });
  },
  created() {
    const _this = this;
    _this.visit = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.visit || _this.visit <= 0) {
      _this.$router.go(-1);
    }
    _this.getActiveTimer();

    if (_this.$route.query && _this.$route.query.tab) {
      _this.visitTab = _this.$route.query.tab;
    }
  },
  components: {
    RecordAudio,
    LineItemNew,
    DeleteDialog,
    Datepicker,
    Timepicker,
    CreateTimeCloser,
    UpdateTooltip,
    DetailTemplate,
    VisitNotesDetail,
    Priority,
    VisitTeamDetail,
    ServiceFormDetail,
    TimeTrackingDetail,
    TicketCloserDetail,
    TicketVisitReminder,
    InternalHistoryDetail,
    UpdateServiceForm,
    Barcode,
    CustomStatus,
    Dialog,
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    canUpdateVisit() {
      if (this.visitDetail && this.visitDetail.status != 1) {
        return false;
      }
      return this.getPermission("visit:update");
    },
    visitTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        if (val != this.visitTab) {
          this.$router.push({ query });
        }
      },
      get() {
        return this.$route.query.tab || "line-item";
      },
    },
    hasServiceForm() {
      if (this.runningTimer) {
        return this.runningTimer.is_service_form_assigned;
      }
      return false;
    },
    hasCloser() {
      if (this.runningTimer && this.runningTimer.closer > 0) {
        return true;
      }
      return false;
    },
    isServiceFormFilled() {
      if (this.runningTimer && this.runningTimer.is_service_form_filled) {
        return true;
      }
      return false;
    },
    updateStartDate() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        return moment(this.visitDetail.finished_at).format("hh:mm A");
      }
      return null;
    },
    getAppointmentText() {
      if (this.lodash.isEmpty(this.visitDetail) === false) {
        let date = this.formatDate(this.visitDetail.started_at);
        let startTime = moment(this.visitDetail.started_at).format("hh:mm A");
        let endTime = moment(this.visitDetail.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
};
</script>
