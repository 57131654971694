<template>
  <v-container class="py-0">
    <v-row class="custom-border-grey-dashed">
      <v-col md="6">
        <audio
          id="audio-player"
          controls
          autoplay
          :src="audioBlob"
          type="audio/mp3"
          class="width-100"
          preload="none"
        />
      </v-col>
      <v-col md="6" class="my-auto">
        <template v-if="recordingStarted">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="stopRecording"
              >
                <v-icon>mdi-microphone-off</v-icon> Stop</v-btn
              >
            </template>
            <span>Click here to stop recording</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-on:click="resumeRecording"
                v-if="recordingPaused"
              >
                <v-icon>mdi-microphone</v-icon> Resume</v-btn
              >
            </template>
            <span>Click here to resume recording</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="pauseRecording"
              >
                <v-icon>mdi-microphone-off</v-icon> Pause</v-btn
              >
            </template>
            <span>Click here to pause recording</span>
          </v-tooltip>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="resetRecording"
              >
                <v-icon>mdi-microphone-settings</v-icon> Reset</v-btn
              >
            </template>
            <span>Click here to reset recording</span>
          </v-tooltip>
        </template>
        <template v-else>
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 custom-grey-border custom-bold-button white--text"
                color="cyan"
                v-bind="attrs"
                v-on="on"
                v-on:click="startRecording"
              >
                <v-icon>mdi-microphone</v-icon> Start</v-btn
              >
            </template>
            <span>Click here to start recording</span>
          </v-tooltip>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RecordRTC from "recordrtc";

export default {
  data() {
    return {
      recorder: null,
      recordingStarted: false,
      recordingPaused: false,
      audioBlob: null,
    };
  },
  model: {
    prop: "audioDataURL",
    event: "input",
  },
  props: {
    audioDataURL: {
      type: [String],
      default: null,
    },
  },
  methods: {
    resetPlayer() {
      let player = document.getElementById("audio-player");
      player.currentTime = 0;
      player.pause();
      player.src = null;
    },
    startRecording() {
      const _this = this;
      _this.audioBlob = null;
      _this.resetPlayer();
      _this.$emit("input", null);
      _this.$emit("start-recording", true);
      _this.$emit("stop-recording", false);
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then(async function (stream) {
          _this.recorder = RecordRTC(stream, {
            type: "audio",
            mimeType: "audio/wav",
            disableLogs: true,
          });
          _this.recorder.startRecording();
          _this.recordingStarted = true;
        });
    },
    stopRecording() {
      const _this = this;
      _this.recorder.stopRecording(function () {
        _this.recordingStarted = false;
        _this.audioBlob = _this.recorder.toURL();
        _this.recorder.getDataURL(function (dataURL) {
          _this.$emit("input", dataURL);
          _this.$emit("stop-recording", true);
        });
      });
    },
    pauseRecording() {
      const _this = this;
      _this.recorder.pauseRecording();
      _this.recordingPaused = true;
    },
    resumeRecording() {
      const _this = this;
      _this.recorder.resumeRecording();
      _this.recordingPaused = false;
    },
    resetRecording() {
      const _this = this;
      _this.recorder.reset();
      _this.recorder = null;
      _this.recordingStarted = false;
      _this.recordingPaused = false;
      _this.audioBlob = null;
      _this.resetPlayer();
      _this.$emit("input", null);
      _this.$emit("stop-recording", true);
    },
  },
};
</script>
