<template>
  <v-container>
    <v-layout v-if="canUpdate">
      <v-flex md6>
        <v-btn-toggle v-model="product_type" color="cyan" group>
          <v-btn
            color="cyan"
            :disabled="loading"
            class="custom-bold-button"
            v-on:click="openDialog('goods', 'Product')"
            value="goods"
          >
            <template v-if="showDetail">Add New</template> Product
          </v-btn>
          <v-btn
            color="cyan"
            :disabled="loading"
            class="custom-bold-button ml-2"
            v-on:click="openDialog('service', 'Service')"
            value="service"
          >
            <template v-if="showDetail">Add New</template> Service
          </v-btn>
        </v-btn-toggle>
      </v-flex>
      <v-flex md6 class="text-right">
        <v-btn
          :disabled="loading"
          v-if="showDetail && save_enabled"
          v-on:click="updateLineItem()"
          small
          depressed
          color="cyan white--text custom-bold-button"
          >Save</v-btn
        >
      </v-flex>
    </v-layout>
    <v-row>
      <v-col md="12">
        <table class="line-item-multiple-table" width="100%">
          <thead>
            <tr>
              <th class="text-left" width="55%">
                &nbsp;&nbsp;&nbsp;Product/Service
              </th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Rate</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Quantity</th>
              <th class="text-left" width="15%">&nbsp;&nbsp;&nbsp;Total</th>
            </tr>
          </thead>
          <tbody v-if="selected_line_items.length">
            <tr v-for="(line_item, index) in selected_line_items" :key="index">
              <td valign="top" class="text-left" width="55%">
                <v-layout>
                  <v-flex md12>
                    <v-text-field
                      label="Product/Service"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                  <v-flex class="my-auto">
                    <v-layout>
                      <v-flex>
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :color="
                            line_item.product_type == 'goods'
                              ? 'cyan darken-1'
                              : 'orange darken-1'
                          "
                        >
                          {{ getProductType(line_item.product_type) }}
                        </v-chip>
                      </v-flex>
                      <v-flex v-if="line_item.visit_barcode">
                        <v-chip
                          small
                          label
                          class="ml-4 custom-status font-weight-600 custom-grey-border text-uppercase"
                          text-color="white"
                          :style="{
                            'background-color': '#7db00e',
                          }"
                        >
                          {{ line_item.visit_barcode }}
                        </v-chip>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
                <v-layout v-if="false">
                  <v-flex class="max-width-50px">
                    <v-text-field
                      dense
                      filled
                      solo
                      flat
                      v-mask="'A'"
                      class="min-width-unset max-width-50px"
                      color="cyan"
                      v-model="line_item.group"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      label="Product/Service"
                      dense
                      filled
                      solo
                      flat
                      readonly
                      color="cyan"
                      v-model="line_item.product"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-textarea
                  auto-grow
                  dense
                  filled
                  flat
                  v-model="line_item.description"
                  label="Description"
                  solo
                  :readonly="!canUpdate"
                  color="cyan"
                  hide-details
                  row-height="10"
                  v-on:change="save_enabled = true"
                ></v-textarea>
              </td>
              <td valign="top" class="text-left" width="15%">
                <v-text-field
                  label="Rate"
                  dense
                  filled
                  solo
                  flat
                  :readonly="!canUpdate"
                  v-on:keyup="calculateTotal(index, 'selected-line-item')"
                  v-on:change="
                    save_enabled = true;
                    calculateTotal(index, 'selected-line-item');
                  "
                  type="number"
                  color="cyan"
                  v-model="line_item.rate"
                ></v-text-field>
              </td>
              <td valign="top" class="text-left" width="15%">
                <v-text-field
                  label="Quantity"
                  dense
                  filled
                  solo
                  flat
                  :readonly="!canUpdate"
                  type="number"
                  color="cyan"
                  v-on:keyup="calculateTotal(index, 'selected-line-item')"
                  v-on:change="
                    save_enabled = true;
                    calculateTotal(index, 'selected-line-item');
                  "
                  :suffix="line_item.uom"
                  v-model="line_item.quantity"
                  v-mask="'####'"
                ></v-text-field>
              </td>
              <td valign="top" align="right" width="15%">
                <v-text-field
                  label="Total"
                  dense
                  filled
                  solo
                  color="cyan"
                  flat
                  type="number"
                  readonly
                  v-model="line_item.total"
                ></v-text-field>
                <v-btn
                  icon
                  color="red lighten-1"
                  v-if="selected_line_items.length > 1"
                  v-on:click="deleteLineItem(line_item, index)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
            <tr>
              <td colspan="4"></td>
            </tr>
            <tr>
              <td colspan="4" class="custom-border-top"></td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Sub Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{ formatMoney(sub_total) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Discount
              </td>
              <td valign="middle" class="p-0">
                <v-layout>
                  <v-flex md8>
                    <v-text-field
                      label="Discount"
                      dense
                      filled
                      solo
                      color="cyan"
                      flat
                      :readonly="!canUpdate"
                      type="number"
                      v-model="discount_value"
                      v-on:keyup="calculateGrandTotal()"
                      v-on:change="
                        save_enabled = true;
                        calculateGrandTotal();
                      "
                    >
                    </v-text-field>
                  </v-flex>
                  <v-flex md4>
                    <v-select
                      :items="discount_type_list"
                      v-model.trim="discount_value_type"
                      dense
                      filled
                      flat
                      hide-details
                      solo
                      :readonly="!canUpdate"
                      item-text="type"
                      item-value="value"
                      color="cyan"
                      item-color="cyan"
                      v-on:change="
                        save_enabled = true;
                        calculateGrandTotal();
                      "
                      class="discount-append-outer"
                    ></v-select>
                  </v-flex>
                </v-layout>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(discount) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td
                valign="middle"
                colspan="2"
                class="font-size-16 py-3 font-weight-500"
              >
                <span class="d-flex align-center"
                  >Tax [GST {{ taxValue }}%]
                  <v-checkbox
                    id="apply-tax"
                    color="cyan"
                    :disabled="!canUpdate"
                    class="ml-4 m-0 p-0"
                    v-model="apply_tax"
                    v-on:change="
                      save_enabled = true;
                      calculateGrandTotal();
                    "
                    hide-details
                  ></v-checkbox>
                </span>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(total_tax) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Adjustment
              </td>
              <td valign="middle" class="p-0">
                <v-text-field
                  label="Adjustment"
                  dense
                  filled
                  solo
                  :readonly="!canUpdate"
                  flat
                  type="number"
                  color="cyan"
                  v-on:keyup="calculateGrandTotal()"
                  v-on:change="
                    save_enabled = true;
                    calculateGrandTotal();
                  "
                  v-model="adjustment"
                ></v-text-field>
              </td>
              <td valign="middle" align="right">
                {{ formatMoney(adjustment) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
            <tr>
              <td valign="middle"></td>
              <td valign="middle" class="font-size-16 py-3 font-weight-500">
                Grand Total
              </td>
              <td valign="middle"></td>
              <td valign="middle" align="right">
                {{ formatMoney(grand_total) }}&nbsp;&nbsp;&nbsp;
              </td>
            </tr>
          </tbody>
          <tfoot v-else>
            <tr>
              <td colspan="4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no product/service at the moment.
                </p>
              </td>
            </tr>
          </tfoot>
        </table>
      </v-col>
    </v-row>
    <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
      <template v-slot:title>
        Select {{ product_type_text }}s
        <v-icon class="mx-2 color-custom-blue" small>mdi-record</v-icon> Line
        Item
      </template>
      <template v-slot:body>
        <v-row class="mx-1">
          <v-col v-if="categories.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in categories"
                v-on:click="getLineItem(row, 'category')"
                :class="{
                  'cyan--text': row == category,
                  'cyan white--text': row != category,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col v-if="alphabets.length" md="12" class="alphabets-filter">
            <div class="alphabets-scrollable">
              <v-btn
                :disabled="searching"
                depressed
                v-for="(row, index) in alphabets"
                v-on:click="getLineItem(row, 'alphabet')"
                :class="{
                  'cyan--text': row == alphabet,
                  'cyan white--text': row != alphabet,
                }"
                class="custom-bold-button"
                small
                :key="index"
                >{{ row }}</v-btn
              >
            </div>
          </v-col>
          <v-col md="12">
            <v-layout>
              <v-flex
                md6
                class="font-weight-700 font-size-17 color-custom-blue"
              >
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  :class="{
                    'green white--text': show_selected,
                    'white green--text': !show_selected,
                  }"
                  class="font-size-17 font-weight-700"
                  v-on:click="show_selected = !show_selected"
                  >{{ selectedCount() }}</v-btn
                >
                out of
                <v-btn
                  small
                  depressed
                  :disabled="searching"
                  class="font-size-17 font-weight-700"
                  color="white cyan--text"
                  v-on:click="showAll()"
                  >{{ totalCount() }}</v-btn
                >
                {{ product_type_text }} Selected
              </v-flex>
              <v-flex md6>
                <v-text-field
                  v-model="search"
                  label="Search"
                  placeholder="Start typing then press enter to search..."
                  dense
                  filled
                  solo
                  flat
                  :disabled="searching"
                  color="cyan"
                  append-icon="mdi-magnify"
                  clearable
                  v-on:change="getLineItem(search, 'search')"
                  v-on:keyup.enter="getLineItem(search, 'search')"
                  v-on:click:append="getLineItem(search, 'search')"
                ></v-text-field>
              </v-flex>
            </v-layout>
            <br />
            <div class="custom-border-top">
              <br />
              <table class="line-item-multiple-table" width="100%">
                <thead>
                  <tr>
                    <th class="text-center" width="5%">
                      <v-checkbox
                        :disabled="searching"
                        color="cyan"
                        hide-details
                        class="m-0 p-0"
                        v-on:change="checkAll($event)"
                      ></v-checkbox>
                    </th>
                    <th class="text-left" width="50%">
                      &nbsp;&nbsp;&nbsp;{{ product_type_text }}
                    </th>
                    <th class="text-left" width="15%">
                      &nbsp;&nbsp;&nbsp;Rate
                    </th>
                    <th class="text-left" width="15%">
                      &nbsp;&nbsp;&nbsp;Quantity
                    </th>
                    <th class="text-left" width="15%">
                      &nbsp;&nbsp;&nbsp;Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(line_item, index) in line_items">
                    <tr v-if="checkSelected(line_item)" :key="index">
                      <td valign="top" class="text-center" width="5%">
                        <v-checkbox
                          color="cyan"
                          hide-details
                          :disabled="searching"
                          v-model="line_item.status"
                          class="m-0 p-0 mt-2"
                        ></v-checkbox>
                      </td>
                      <td valign="top" class="text-left" width="50%">
                        <v-text-field
                          :label="product_type_text"
                          dense
                          filled
                          solo
                          flat
                          :disabled="searching"
                          readonly
                          color="cyan"
                          v-model="line_item.product"
                        ></v-text-field>
                        <v-textarea
                          auto-grow
                          dense
                          filled
                          flat
                          :disabled="searching"
                          v-model="line_item.description"
                          label="Description"
                          solo
                          color="cyan"
                          hide-details
                          row-height="10"
                        ></v-textarea>
                      </td>
                      <td valign="top" class="text-left" width="15%">
                        <v-text-field
                          label="Rate"
                          dense
                          filled
                          solo
                          v-on:keyup="calculateTotal(index, 'line-item')"
                          v-on:change="calculateTotal(index, 'line-item')"
                          :disabled="searching"
                          flat
                          type="number"
                          color="cyan"
                          v-model="line_item.rate"
                        ></v-text-field>
                      </td>
                      <td valign="top" class="text-left" width="15%">
                        <v-text-field
                          label="Quantity"
                          dense
                          filled
                          solo
                          flat
                          type="number"
                          v-on:keyup="calculateTotal(index, 'line-item')"
                          v-on:change="calculateTotal(index, 'line-item')"
                          :disabled="searching"
                          color="cyan"
                          :suffix="line_item.uom"
                          v-model="line_item.quantity"
                          v-mask="'####'"
                        ></v-text-field>
                      </td>
                      <td valign="top" class="text-left" width="15%">
                        <v-text-field
                          label="Total"
                          dense
                          filled
                          :disabled="searching"
                          solo
                          color="cyan"
                          flat
                          type="number"
                          readonly
                          v-model="line_item.total"
                        ></v-text-field>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          v-on:click="
            product_type = null;
            product_type_text = null;
            line_items = [];
            categories = [];
            dialog = false;
          "
          :disabled="searching"
          class="custom-grey-border custom-bold-button"
          >Cancel</v-btn
        >
        <v-btn
          color="cyan white--text"
          class="custom-bold-button"
          v-on:click="saveLineItem()"
          :disabled="searching"
          >Save</v-btn
        >
      </template>
    </Dialog>
    <DeleteDialog :delete-dialog="delete_line_item_dialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_line_item.product }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_line_item_dialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="deleteLineItemConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
  </v-container>
</template>
<script>
import { QUERY, DELETE } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import CommonFunctionMixin from "@/core/lib/common/common.functions.mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import {
  toNumber,
  sum,
  round,
  filter,
  concat,
  uniqBy,
  find,
  toSafeInteger,
} from "lodash";

export default {
  mixins: [CommonFunctionMixin],
  data() {
    return {
      search: null,
      searching: false,
      save_enabled: false,
      product_type: null,
      product_type_text: null,
      dialog: false,
      categories: [],
      alphabet: null,
      category: null,
      alphabets: [
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ],
      timeout: null,
      timeout_limit: 500,
      line_items: [],
      selected_line_items: [],
      show_selected: false,
      apply_tax: false,
      sub_total: 0,
      discount: 0,
      total_tax: 0,
      adjustment: 0,
      grand_total: 0,
      discount_value: 0,
      discount_value_type: 1,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      delete_loading: false,
      delete_line_item_dialog: false,
      delete_line_item: {},
    };
  },
  props: {
    isInvoice: {
      type: Boolean,
      default: false,
    },
    isJob: {
      type: Boolean,
      default: false,
    },
    showDetail: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    dbLineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    discountValue: {
      type: Number,
      default: 0,
    },
    discountValueType: {
      type: Number,
      default: 0,
    },
    applyTax: {
      type: Number,
      default: 0,
    },
    taxValue: {
      type: Number,
      default: 8,
    },
    adjustmentValue: {
      type: Number,
      default: 0,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dbLineItems: {
      deep: true,
      immediate: true,
      handler() {
        this.initUpdate();
      },
    },
  },
  methods: {
    getProductType(type) {
      let result = null;
      switch (type) {
        case "goods":
          result = "Product";
          break;
        case "service":
          result = "Service";
          break;
      }
      return result;
    },
    initUpdate() {
      this.selected_line_items = [];
      if (this.dbLineItems.length) {
        for (let i = 0; i < this.dbLineItems.length; i++) {
          this.selected_line_items.push({
            status: true,
            id: this.dbLineItems[i].id,
            visit_barcode: this.dbLineItems[i].visit_barcode,
            group: this.dbLineItems[i].group,
            group_primary: this.dbLineItems[i].group_primary,
            product: this.dbLineItems[i].product,
            product_id: this.dbLineItems[i].product_id,
            product_type: this.dbLineItems[i].product_type,
            description: this.dbLineItems[i].description,
            rate: this.dbLineItems[i].rate,
            quantity: this.dbLineItems[i].quantity,
            uom: this.dbLineItems[i].uom,
            total: this.dbLineItems[i].total,
          });
          this.$nextTick(() => {
            this.calculateTotal(i, "selected-line-item");
          });
        }
      }

      this.apply_tax = this.applyTax;
      this.adjustment = this.adjustmentValue;
      this.discount_value = this.discountValue;
      this.discount_value_type = this.discountValueType;

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    deleteLineItem(row, index) {
      if (row.id) {
        this.delete_line_item = row;
        this.delete_line_item_dialog = true;
      } else {
        this.selected_line_items.splice(index, 1);
      }
    },
    deleteLineItemConfirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, { url: `line-item/${this.delete_line_item.id}` })
        .then(() => {
          this.$emit("load:content", true);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    calculateGrandTotal() {
      const item_total = [];

      for (let i = 0; i < this.selected_line_items.length; i++) {
        const { rate, quantity } = this.selected_line_items[i];
        const total = round(toNumber(rate), 2) * round(toNumber(quantity), 2);
        item_total.push(total);
      }

      let sub_total = sum(item_total);

      this.sub_total = round(toNumber(sub_total), 2);

      if (this.discount_value_type == 1) {
        this.discount = round(toNumber(this.discount_value), 2);
      }

      if (this.discount_value_type == 2) {
        this.discount =
          (round(toNumber(sub_total), 2) *
            round(toNumber(this.discount_value), 2)) /
          100;
      }

      sub_total = sub_total - round(toNumber(this.discount), 2);

      let tax_value = toNumber(this.taxValue);

      if (!tax_value) {
        tax_value = 9;
      }

      if (this.apply_tax) {
        this.total_tax = (round(toNumber(sub_total), 2) * tax_value) / 100;
      } else {
        this.total_tax = 0;
      }

      sub_total = sub_total + round(toNumber(this.total_tax), 2);

      sub_total = sub_total + round(toNumber(this.adjustment), 2);

      this.grand_total = round(toNumber(sub_total), 2);

      this.$nextTick(() => {
        const calculations = {
          apply_tax: toSafeInteger(this.apply_tax),
          adjustment: round(toNumber(this.adjustment), 2),
          discount_value: round(toNumber(this.discount_value), 2),
          discount_value_type: toSafeInteger(this.discount_value_type),
        };

        if (!this.showDetail) {
          this.$emit("update:line-item", this.selected_line_items);
          this.$emit("update:line-item-calculation", calculations);
        }
      });
    },
    updateLineItem() {
      const selected_line_items = filter(this.line_items, {
        status: true,
      });

      this.selected_line_items = concat(
        selected_line_items,
        this.selected_line_items
      );

      this.selected_line_items = uniqBy(this.selected_line_items, "product_id");

      this.calculateGrandTotal();

      this.$nextTick(() => {
        const calculations = {
          apply_tax: toSafeInteger(this.apply_tax),
          adjustment: round(toNumber(this.adjustment), 2),
          discount_value: round(toNumber(this.discount_value), 2),
          discount_value_type: toSafeInteger(this.discount_value_type),
        };

        this.$emit("update:line-item", this.selected_line_items);
        this.$emit("update:line-item-calculation", calculations);
        this.save_enabled = false;
      });
    },
    calculateTotal(index, type) {
      let item = this.selected_line_items[index];

      if (type == "line-item") {
        item = this.line_items[index];
      }

      if (item) {
        const total = toNumber(item.rate) * toNumber(item.quantity);
        if (type == "line-item") {
          this.line_items[index].total = total;
        } else {
          this.selected_line_items[index].total = total;
        }
      }

      if (type == "selected-line-item") {
        this.$nextTick(() => {
          this.calculateGrandTotal();
        });
      }
    },
    openDialog(type, type_text) {
      this.product_type_text = type_text;
      this.product_type = type;
      this.findLineItem(true);
    },
    showAll() {
      this.show_selected = false;
    },
    selectedCount() {
      return filter(this.line_items, { status: true }).length;
    },
    totalCount() {
      return this.line_items.length;
    },
    checkSelected(line_item) {
      if (this.show_selected) {
        return line_item.status;
      }
      return true;
    },
    checkAll(event) {
      if (event) {
        for (let i = this.line_items.length - 1; i >= 0; i--) {
          this.line_items[i].status = true;
        }
      } else {
        for (let z = this.line_items.length - 1; z >= 0; z--) {
          this.line_items[z].status = false;
        }
      }
    },
    getLineItem(value, type) {
      const _this = this;
      if (type == "category") {
        _this.category = _this.category != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "alphabet") {
        _this.alphabet = _this.alphabet != value ? value : null;
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "search") {
        _this.$nextTick(() => {
          _this.findLineItem(false);
        });
      }
      if (type == "keyup") {
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(() => {
          _this.findLineItem(false);
        }, _this.timeout_limit);
      }
    },
    findLineItem(dialog) {
      const _this = this;
      if (!_this.product_type) {
        return false;
      }
      _this.searching = true;
      _this.$store
        .dispatch(QUERY, {
          url: "product/find-line-items",
          data: {
            type: _this.product_type,
            search: _this.search,
            alphabet: _this.alphabet,
            category: _this.category,
          },
        })
        .then(({ data }) => {
          _this.line_items = data.line_items;
          _this.categories = data.categories;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.searching = false;
          _this.updateChecked(dialog);
        });
    },
    saveLineItem() {
      const selected_line_items = filter(this.line_items, {
        status: true,
      });

      if (!selected_line_items.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            `Select atleast one ${this.product_type_text.toLowerCase()} then try again.`
          )
        );
        return false;
      }

      this.selected_line_items = concat(
        selected_line_items,
        this.selected_line_items
      );

      this.selected_line_items = uniqBy(this.selected_line_items, "product_id");

      this.product_type = null;
      this.product_type_text = null;
      this.dialog = false;

      this.save_enabled = true;

      this.$nextTick(() => {
        this.calculateGrandTotal();
      });
    },
    updateChecked(dialog) {
      const filtered = filter(this.selected_line_items, {
        product_type: this.product_type,
      });

      for (let i = 0; i < this.line_items.length; i++) {
        const exist = find(filtered, {
          product_id: this.line_items[i].product_id,
        });
        if (exist) {
          this.line_items[i] = exist;
        }
      }

      if (dialog) {
        this.$nextTick(() => {
          this.dialog = true;
        });
      }
    },
  },
  components: {
    Dialog,
    DeleteDialog,
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
