<template>
  <Dialog :commonDialog="closerDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      Closer# {{ closer.barcode
      }}<v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >Visit# {{ closer.visit.barcode }}
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 60vh; position: relative"
      >
        <v-container>
          <v-row>
            <v-col v-if="false" md="6" class="custom-border-right">
              <p
                class="m-0 pb-4 font-size-18 font-weight-600 custom-nowrap-ellipsis-two-line color-custom-blue"
              >
                {{ closer.visit.title }}
              </p>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="lodash.isEmpty(customer) === false"
                    class="py-1 px-0 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-skeleton-loader
                        v-if="pageLoading"
                        class="custom-skeleton"
                        type="text"
                      ></v-skeleton-loader>
                      <v-list-item-title
                        v-else
                        class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                      >
                        <router-link
                          :to="
                            getDefaultRoute('customer.detail', {
                              params: {
                                id: customer.customer,
                              },
                            })
                          "
                          >{{ detail.attention }}</router-link
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      link
                      class="m-0 ml-4"
                      v-on:click="
                        $router.push(
                          getDefaultRoute('customer.detail', {
                            params: {
                              id: customer.customer,
                            },
                          })
                        )
                      "
                    >
                      <v-icon class="color-custom-blue"
                        >mdi-link mdi-rotate-135</v-icon
                      >
                    </v-list-item-action>
                  </v-list-item>
                </template>
                <span>Attention</span>
              </v-tooltip>
            </v-col>
            <v-col v-if="false" md="6">
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item
                    v-if="contactPerson"
                    class="py-1 max-content-width"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                      >
                        <a :href="'tel:' + contactPerson.primary_phone">{{
                          contactPerson.primary_phone
                        }}</a>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Contact Phone</span>
              </v-tooltip>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item class="py-1" v-bind="attrs" v-on="on">
                    <v-list-item-icon class="margin-auto-zero mr-4">
                      <v-icon class="font-size-28">mdi-home-map-marker</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="p-0">
                      <v-list-item-title
                        class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                      >
                        <a
                          target="_blank"
                          :href="'https://maps.google.com/?q=' + PropertyDetail"
                          >{{ PropertyDetail }}</a
                        >
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <span>Property Address</span>
              </v-tooltip>
            </v-col>
            <v-col>
              <v-tabs
                v-model="closerTab"
                background-color="transparent"
                centered
                color="cyan"
                fixed-tabs
                class="custom-tab-transparent tab-sticky"
              >
                <template v-if="getPermission('visit:view')">
                  <v-tab
                    class="font-size-16 font-weight-600 color-custom-blue"
                    :href="'#tab-visit'"
                  >
                    <v-icon color="grey darken-4" left></v-icon> Visit
                  </v-tab>
                </template>
                <v-tab
                  class="font-size-16 font-weight-600 color-custom-blue"
                  :href="'#tab-detail'"
                >
                  <v-icon color="grey darken-4" left></v-icon> Detail
                </v-tab>
                <v-tab
                  v-if="$haveRecordAudio"
                  class="font-size-16 font-weight-600 color-custom-blue"
                  :href="'#tab-audio'"
                >
                  <v-icon color="grey darken-4" left></v-icon> Audio
                </v-tab>
                <template v-if="getPermission('attachment:view')">
                  <v-tab
                    class="font-size-16 font-weight-600 color-custom-blue"
                    :href="'#tab-attachment'"
                  >
                    <v-icon color="grey darken-4" left></v-icon>
                    <v-badge
                      v-if="closer.attachments.length"
                      bordered
                      color="orange darken-4"
                      :content="closer.attachments.length"
                    >
                      {{
                        closer.attachments.length > 1
                          ? "Attachments"
                          : "Attachment"
                      }}
                    </v-badge>
                    <template v-else> Attachment </template>
                  </v-tab>
                </template>
                <v-tab
                  class="font-size-16 font-weight-600 color-custom-blue"
                  :href="'#tab-signature'"
                >
                  <v-icon color="grey darken-4" left></v-icon> Signature
                </v-tab>
              </v-tabs>
              <v-tabs-items class="closer-tab" v-model="closerTab">
                <template v-if="getPermission('visit:view')">
                  <v-tab-item :value="'tab-visit'">
                    <v-container>
                      <v-row>
                        <v-col md="6" class="py-0">
                          <h4
                            class="font-size-16 font-weight-600 color-custom-blue"
                          >
                            Visit
                          </h4>
                        </v-col>
                      </v-row>
                      <div class="closer-visit custom-border-top">
                        <table class="width-100">
                          <tr v-if="false" class="custom-border-bottom">
                            <td
                              class="font-weight-500 font-size-16"
                              width="150"
                            >
                              Job #
                            </td>
                            <td
                              class="font-weight-500 font-size-16 color-custom-blue"
                            >
                              <Barcode :barcode="detail.barcode"></Barcode>
                            </td>
                          </tr>
                          <tr class="custom-border-bottom" v-if="closer.visit">
                            <td
                              class="font-weight-500 font-size-16"
                              width="150"
                            >
                              Visit #
                            </td>
                            <td
                              class="font-weight-500 font-size-16 color-custom-blue"
                            >
                              <Barcode
                                :barcode="closer.visit.barcode"
                              ></Barcode>
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="font-weight-500 font-size-16"
                              width="150"
                            >
                              Property #
                            </td>
                            <td
                              class="font-weight-500 font-size-16 color-custom-blue"
                            >
                              <Barcode :barcode="property.barcode"></Barcode>
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              Appointment
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{ getAppointmentText }}
                            </td>
                          </tr>
                          <tr v-if="false">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              Instructions
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              <template v-if="closer.visit.instruction">
                                <read-more
                                  class="custom-read-more mt-2 font-weight-600 font-size-16 custom-gray-color"
                                  more-str="read more"
                                  :text="closer.visit.instruction"
                                  link="#"
                                  less-str="read less"
                                  :max-chars="300"
                                >
                                </read-more>
                              </template>
                              <em v-else>No additional instructions</em>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-container>
                    <v-container v-if="closer.timesheet">
                      <v-row>
                        <v-col md="6" class="py-0">
                          <h4
                            class="font-size-16 font-weight-600 color-custom-blue"
                          >
                            Timesheet
                          </h4>
                        </v-col>
                      </v-row>
                      <div class="closer-visit-team custom-border-top">
                        <table class="width-100">
                          <tr class="custom-border-bottom">
                            <td
                              class="font-weight-500 font-size-16"
                              width="150"
                            >
                              Timesheet #
                            </td>
                            <td
                              class="font-weight-500 font-size-16 color-custom-blue"
                            >
                              <Barcode
                                :barcode="closer.timesheet.barcode"
                              ></Barcode>
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              Start Date
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{ formatDate(closer.timesheet.date) }}
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              Start Time
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{
                                formatTime(
                                  closer.timesheet.date +
                                    " " +
                                    closer.timesheet.start_at
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              End Date
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{ formatDate(closer.timesheet.end_date) }}
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              End Time
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{
                                formatTime(
                                  closer.timesheet.end_date +
                                    " " +
                                    closer.timesheet.end_at
                                )
                              }}
                            </td>
                          </tr>
                          <tr class="custom-border-bottom">
                            <td
                              class="py-4 font-weight-500 font-size-16"
                              width="150"
                            >
                              Duration
                            </td>
                            <td
                              class="py-4 font-weight-500 font-size-16 color-custom-blue"
                            >
                              {{ closer.timesheet.duration }} Minutes
                            </td>
                          </tr>
                        </table>
                      </div>
                    </v-container>
                    <v-container>
                      <v-row>
                        <v-col md="6" class="py-0">
                          <h4
                            class="font-size-16 font-weight-600 color-custom-blue"
                          >
                            Team
                          </h4>
                        </v-col>
                      </v-row>
                      <div class="closer-visit-team custom-border-top">
                        <div
                          v-if="
                            lodash.isEmpty(closer.visit.engineers) === false
                          "
                        >
                          <template
                            v-for="(data, index) in closer.visit.engineers"
                          >
                            <v-list-item :key="index" v-if="data.engineer">
                              <v-list-item-avatar size="50">
                                <v-img
                                  :lazy-src="$defaultProfileImage"
                                  :src="
                                    $assetAPIURL(data.engineer.profile_logo)
                                  "
                                ></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content
                                class="px-6 py-2 custom-border-left"
                              >
                                <v-list-item-title
                                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                  v-html="data.engineer.display_name"
                                ></v-list-item-title>
                                <v-list-item-subtitle
                                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                  v-html="data.engineer.primary_email"
                                ></v-list-item-subtitle>
                                <v-list-item-subtitle
                                  class="font-size-16 font-weight-500 custom-nowrap-ellipsis"
                                  v-html="data.engineer.primary_phone"
                                ></v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider
                              v-if="closer.visit.engineers[index + 1]"
                              inset
                              :key="'divider' + index"
                            ></v-divider>
                          </template>
                        </div>
                        <em v-else class="line-height-3 py-4 text--secondary"
                          >Not assigned yet.</em
                        >
                      </div>
                    </v-container>
                    <v-container>
                      <v-row>
                        <v-col md="6" class="py-0">
                          <h4
                            class="font-size-16 font-weight-600 color-custom-blue"
                          >
                            Reminders
                          </h4>
                        </v-col>
                      </v-row>
                      <div class="closer-reminder custom-border-top">
                        <em class="line-height-3 py-4 text--secondary"
                          >No reminders found.</em
                        >
                      </div>
                    </v-container>
                  </v-tab-item>
                </template>
                <v-tab-item :value="'tab-detail'">
                  <v-container>
                    <v-row>
                      <v-col md="6" class="py-0">
                        <h4
                          class="font-size-16 font-weight-600 color-custom-blue"
                        >
                          Detail
                        </h4>
                      </v-col>
                    </v-row>
                    <div class="closer-detail custom-border-top">
                      <read-more
                        class="custom-read-more mt-2 font-weight-600 font-size-16 custom-gray-color"
                        more-str="read more"
                        :text="closer.details ? closer.details : ''"
                        link="#"
                        less-str="read less"
                        :max-chars="300"
                      >
                      </read-more>
                    </div>
                  </v-container>
                </v-tab-item>
                <v-tab-item v-if="$haveRecordAudio" :value="'tab-audio'">
                  <div class="pt-2">
                    <v-list v-if="lodash.isEmpty(closer.audio) == false">
                      <template v-for="(item, index) in closer.audio">
                        <v-list-item
                          :key="index"
                          :class="{ 'custom-border-top': index > 0 }"
                        >
                          <v-list-item-content class="px-4 custom-border-right">
                            <audio
                              controls
                              :src="item.audio"
                              type="audio/mp3"
                              class="width-100"
                              preload="none"
                            />
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-title
                              class="font-weight-600 font-size-16"
                              >{{
                                formatDateTime(item.added_at)
                              }}</v-list-item-title
                            >
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-list>
                    <em v-else class="font-weight-600 font-size-16"
                      >No Audio...</em
                    >
                  </div>
                </v-tab-item>
                <template v-if="getPermission('attachment:view')">
                  <v-tab-item :value="'tab-attachment'">
                    <v-container
                      v-if="closer.attachments && closer.attachments.length > 0"
                    >
                      <div
                        class="closer-attachment"
                        v-if="lodash.isEmpty(closer.attachments) === false"
                      >
                        <FileTemplate
                          isMinDisplay
                          :allowDelete="false"
                          :attachments="closer.attachments"
                        ></FileTemplate>
                      </div>
                    </v-container>
                    <div class="py-4" v-else>
                      <em class="font-weight-600 py-4 font-size-16"
                        >No Attachment...</em
                      >
                    </div>
                  </v-tab-item>
                </template>
                <v-tab-item :value="'tab-signature'">
                  <v-container>
                    <v-row v-if="lodash.isEmpty(closer.signatures) === false">
                      <template v-for="(signature, index) in closer.signatures">
                        <v-col md="6" class="py-0" :key="'signature-' + index">
                          <template v-for="(sign, indx) in signature">
                            <h4
                              class="font-size-16 font-weight-600 color-custom-blue text-uppercase"
                              :key="'signature-' + indx"
                            >
                              {{ indx }}
                            </h4>
                            <div
                              class="closer-signature custom-border-top py-5"
                              :key="'signature-image-' + indx"
                            >
                              <v-img
                                :lazy-src="$defaultImage"
                                class="custom-border-grey-dashed"
                                :src="sign"
                              ></v-img>
                            </div>
                          </template>
                        </v-col>
                      </template>
                    </v-row>
                  </v-container>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close-dialog', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import moment from "moment-timezone";
import CommonMixin from "@/core/plugins/common-mixin";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { GET } from "@/core/services/store/request.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    closerDialog: {
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    closer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  data() {
    return {
      closerTab: null,
      timeTrackings: [],
    };
  },
  components: {
    Dialog,
    Barcode,
    FileTemplate,
  },
  methods: {
    getTimeTracking(timesheets) {
      const _this = this;
      if (_this.lodash.isEmpty(timesheets) === false) {
        _this.$store
          .dispatch(GET, {
            url: "job/" + _this.detail.id + "/time-tracking/" + timesheets,
          })
          .then(({ data }) => {
            _this.timeTrackings = data;
          })
          .catch((error) => {
            _this.logError(error);
          });
      }
    },
  },
  mounted() {
    if (this.lodash.isEmpty(this.closer) === false) {
      let timesheets = this.closer.timesheets;
      this.getTimeTracking(timesheets);
    }
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
    getAppointmentText() {
      if (this.closer.visit) {
        let date = this.formatDate(this.closer.visit.started_at);
        let startTime = moment(this.closer.visit.started_at).format("hh:mm A");
        let endTime = moment(this.closer.visit.finished_at).format("hh:mm A");
        return date + " " + startTime + " - " + endTime;
      }
      return null;
    },
  },
};
</script>
