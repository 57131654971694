<template>
  <div class="time-tracking-detail" v-if="getPermission('timesheet:view')">
    <v-container fluid class="custom-bthrust-style">
      <v-container>
        <v-layout class="justify-end">
          <div class="mr-2">
            <v-text-field
              v-model.trim="filter.search"
              v-on:click:clear="clearFilter('search')"
              clearable
              dense
              filled
              flat
              label="Search"
              hide-details
              solo
              :disabled="timesheetLoading"
              color="cyan"
              @keydown.enter="getTimeTracking"
              @keydown.tab="getTimeTracking"
            ></v-text-field>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.visit"
              hide-details
              :items="visitList"
              clearable
              dense
              flat
              filled
              label="Visit"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="timesheetLoading"
              item-text="barcode"
              item-value="id"
              v-on:change="getTimeTracking"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Visit(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <p class="m-0 font-size-16 text-ellipsis text-capitalize">
                  {{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                  {{ formatTime(item.finished_at) }})
                </p>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title
                    class="font-size-16 pl-2 text-ellipsis pb-1 text-capitalize"
                    >{{ item.barcode }} ({{ formatDateTime(item.started_at) }} -
                    {{ formatTime(item.finished_at) }})</v-list-item-title
                  >
                  <v-list-item-subtitle
                    class="font-size-14 pl-2 text-ellipsis pb-1"
                    >{{ item.title }}</v-list-item-subtitle
                  >
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-autocomplete
              v-model.trim="filter.engineer"
              hide-details
              :items="engineerList"
              clearable
              dense
              flat
              filled
              label="Engineer"
              item-color="cyan"
              color="cyan"
              solo
              :disabled="timesheetLoading"
              item-text="display_name"
              item-value="id"
              v-on:change="getTimeTracking"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    v-html="'No Engineer(s) Found.'"
                  ></v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-avatar>
                  <v-img
                    :lazy-src="$defaultProfileImage"
                    :src="$assetAPIURL(item.profile_logo)"
                    aspect-ratio="1"
                    class="margin-auto grey lighten-5 custom-grey-border"
                    transition="fade-transition"
                  ></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >#{{ item.barcode }}</v-list-item-title
                  >
                  <v-list-item-title
                    class="text-capitalize font-weight-500 font-size-16"
                    >{{ item.display_name }}</v-list-item-title
                  >
                </v-list-item-content>
                <v-list-item-action class="align-self-center">
                  <v-list-item-subtitle
                    class="text-lowercase pb-2 font-weight-500 font-size-14"
                    >{{ item.primary_email }}</v-list-item-subtitle
                  >
                  <v-list-item-subtitle
                    class="text-lowercase font-weight-500 font-size-14"
                    >{{ item.primary_phone }}</v-list-item-subtitle
                  >
                </v-list-item-action>
              </template>
            </v-autocomplete>
          </div>
          <div class="mx-2" v-if="!isVisitDetail">
            <v-menu
              v-model="datePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  filled
                  flat
                  label="Date Range"
                  hide-details
                  solo
                  :disabled="timesheetLoading"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-on:click:clear="clearFilter('dates')"
                  clearable
                  v-bind="attrs"
                  v-on="on"
                  :value="formattedDate"
                  color="cyan"
                ></v-text-field>
              </template>
              <v-date-picker
                color="cyan"
                range
                :disabled="timesheetLoading"
                v-on:change="getTimeTracking"
                v-model="dates"
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="ml-2 text-right">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="timesheetLoading"
                  v-on:click="getTimeTracking"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </template>
              <span>Search</span>
            </v-tooltip>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2 custom-bold-button white--text"
                  color="cyan"
                  tile
                  :disabled="timesheetLoading"
                  v-on:click="getTimeTracking"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Reload</span>
            </v-tooltip>
            <template v-if="false">
              <template v-if="selectedRows.length > 0">
                <template v-if="getPermission('closer:create')">
                  <v-tooltip top content-class="custom-top-tooltip">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-2 custom-bold-button white--text"
                        color="cyan"
                        tile
                        :disabled="timesheetLoading"
                        v-on:click="createTicketCloser"
                      >
                        <v-icon>mdi-file-document-edit-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Close</span>
                  </v-tooltip>
                </template>
                <template v-if="getPermission('visit:update')">
                  <template v-if="isVisitDetail">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="ml-2 custom-bold-button white--text"
                          color="cyan"
                          tile
                          :disabled="timesheetLoading"
                          v-on:click="forceStopTimerDialog = true"
                        >
                          <v-icon>mdi-clock-check-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>Force Stop</span>
                    </v-tooltip>
                  </template>
                </template>
              </template>
              <template v-if="getPermission('timesheet:create')">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      class="mx-2 custom-bold-button white--text"
                      color="cyan"
                      tile
                      :disabled="timesheetLoading"
                      v-on:click="createTimeTracking"
                    >
                      <v-icon>mdi-account-clock-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Create Timesheet</span>
                </v-tooltip>
              </template>
            </template>
          </div>
        </v-layout>
      </v-container>
      <v-skeleton-loader
        type="text@10"
        class="custom-skeleton table-rows-text"
        v-if="timesheetLoading"
      ></v-skeleton-loader>
      <v-simple-table
        v-else
        class="inner-simple-table custom-border-top"
        fixed-header
      >
        <template v-slot:default>
          <thead>
            <tr class="custom-border-bottom">
              <td class="font-size-16 font-weight-700" v-if="false">
                <v-checkbox
                  dense
                  v-model="selectedAll"
                  color="cyan"
                  class="hide-margin"
                  hide-details
                ></v-checkbox>
              </td>
              <td class="font-size-16 font-weight-700">#</td>
              <td class="font-size-16 font-weight-700">Closer #</td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Engineer
              </td>
              <td class="font-size-16 font-weight-700" v-if="!isVisitDetail">
                Visit
              </td>
              <td class="font-size-16 font-weight-700">Date</td>
              <td class="font-size-16 font-weight-700">Duration</td>
            </tr>
          </thead>
          <tbody>
            <template v-if="lodash.isEmpty(timeTrackings) === false">
              <template v-for="(row, index) in timeTrackings">
                <v-hover v-slot="{ hover }" :key="index">
                  <tr
                    link
                    :key="'time-tracking-row' + index"
                    :class="{
                      'time-tracking-row-hover': hover,
                      'custom-border-bottom': timeTrackings[index + 1],
                      'time-tracking-working': row.status == 1,
                    }"
                    v-on:click="updateTimeTracking(row)"
                  >
                    <td
                      v-if="false"
                      width="50"
                      class="font-size-16font-weight-500"
                      v-on:click.stop.prevent
                    >
                      <v-checkbox
                        dense
                        :disabled="lodash.isEmpty(row.closer) === false"
                        v-model="selectedRows"
                        v-bind:value="row.id"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </td>
                    <td class="font-size-16 font-weight-500" width="100">
                      <Barcode :barcode="row.barcode"></Barcode>
                    </td>
                    <td class="font-size-16 font-weight-500" width="100">
                      <span v-if="row.closer">
                        <Barcode :barcode="row.closer.barcode"></Barcode>
                      </span>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <v-list-item-avatar size="50" v-if="row.engineer">
                        <v-tooltip top content-class="custom-top-tooltip">
                          <template v-slot:activator="{ on, attrs }">
                            <v-img
                              v-bind="attrs"
                              v-on="on"
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(row.engineer.profile_logo)"
                              aspect-ratio="1"
                              class="margin-auto white--background"
                              transition="fade-transition"
                            ></v-img>
                          </template>
                          <span v-if="row.engineer">{{
                            row.engineer.display_name
                          }}</span>
                        </v-tooltip>
                      </v-list-item-avatar>
                    </td>
                    <td
                      class="font-size-16 font-weight-500"
                      v-if="!isVisitDetail"
                    >
                      <template v-if="row.visit">
                        <Barcode :barcode="row.visit.barcode"></Barcode>
                        <p class="m-0 custom-nowrap-ellipsis">
                          {{ formatDateTime(row.visit.started_at) }} -
                          {{ formatTime(row.visit.finished_at) }}
                        </p>
                      </template>
                      <template v-else> - </template>
                    </td>
                    <td class="font-size-16 font-weight-500">
                      <p class="m-0 custom-nowrap-ellipsis">
                        {{ formatDateTime(row.start_at) }}
                        <template v-if="row.status != 1">
                          -
                          {{ formatDateTime(row.end_at) }}
                        </template>
                      </p>
                    </td>
                    <td class="font-size-16 font-weight-500">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <template v-if="row.status != 1">
                          {{ row.duration_human }}
                        </template>
                        <template v-else> - </template>
                        <v-progress-circular
                          v-if="row.status == 1"
                          class="float-right"
                          indeterminate
                          color="cyan"
                        ></v-progress-circular>
                      </p>
                    </td>
                  </tr>
                </v-hover>
              </template>
            </template>
            <tr v-else>
              <td colspan="6" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no timesheet at the moment.
                </p>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="totalTimeString">
            <tr class="custom-border-top">
              <td
                :colspan="isVisitDetail ? 3 : 5"
                class="font-size-16 font-weight-700 text-right"
              >
                Total Duration
              </td>
              <td class="font-size-16 font-weight-700 text-lowercase">
                {{ totalTimeString }}
              </td>
            </tr>
          </tfoot>
        </template>
      </v-simple-table>
    </v-container>
    <template v-if="getPermission('closer:create')">
      <template v-if="closerDialog">
        <CreateTimeCloser
          :isVisitDetail="isVisitDetail"
          :closerDialog="closerDialog"
          :detail="detail"
          :timesheet="timesheets"
          :visit="visit"
          v-on:create:ticket-closer="createdTimeCloser"
          v-on:close-dialog="closerDialog = false"
        ></CreateTimeCloser>
      </template>
    </template>
    <template v-if="getPermission('timesheet:create')">
      <template v-if="timeTrackingDialog">
        <CreateTimeTracking
          :visit="visitDetail"
          :timeTrackingDialog="timeTrackingDialog"
          :detail="detail"
          :update="updateData"
          v-on:create:time-tracking="updatedTimeTracking"
          v-on:close-dialog="closeTrackingDialog"
        ></CreateTimeTracking>
      </template>
    </template>
    <template v-if="getPermission('timesheet:view')">
      <Dialog :commonDialog="timerDetailDialog" :dialogWidth="dialogWidth">
        <template v-slot:title
          >Timesheet# {{ timesheetDetail.barcode }}
          <template v-if="lodash.isEmpty(timesheetDetail.visit) === false">
            <v-icon small class="mx-4 color-custom-blue"
              >mdi-checkbox-blank-circle</v-icon
            >
            Visit# {{ timesheetDetail.visit.barcode }}
          </template>
        </template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="6" class="custom-border-right">
                <table>
                  <tbody>
                    <tr>
                      <th class="font-size-18">Start Date</th>
                      <td class="py-2 px-4 font-weight-600 font-size-18">
                        {{ formatDate(timesheetDetail.date) }}
                      </td>
                    </tr>
                    <tr>
                      <th class="font-size-18">Start Time</th>
                      <td class="py-2 px-4 font-weight-600 font-size-18">
                        {{ formatTime(timesheetDetail.start_at) }}
                      </td>
                    </tr>
                    <tr>
                      <th class="font-size-18">End Date</th>
                      <td class="py-2 px-4 font-weight-600 font-size-18">
                        {{ formatDate(timesheetDetail.end_date) }}
                      </td>
                    </tr>
                    <tr>
                      <th class="font-size-18">End Time</th>
                      <td class="py-2 px-4 font-weight-600 font-size-18">
                        {{ formatTime(timesheetDetail.end_at) }}
                      </td>
                    </tr>
                    <tr>
                      <th class="font-size-18">Duration</th>
                      <td class="py-2 px-4 font-weight-600 font-size-18">
                        {{ timesheetDetail.duration_human }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col md="6">
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      v-if="lodash.isEmpty(customer) === false"
                      class="py-1 max-content-width"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-icon class="margin-auto-zero mr-4">
                        <v-icon class="font-size-28">mdi-account</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="p-0">
                        <v-skeleton-loader
                          v-if="pageLoading"
                          class="custom-skeleton"
                          type="text"
                        ></v-skeleton-loader>
                        <v-list-item-title
                          v-else
                          class="custom-nowrap-ellipsis text-capitalize font-size-18 font-weight-500"
                        >
                          <router-link
                            :to="
                              getDefaultRoute('customer.detail', {
                                params: {
                                  id: customer.customer,
                                },
                              })
                            "
                            >{{ detail.attention }}</router-link
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action
                        link
                        class="m-0 ml-4"
                        v-on:click="
                          $router.push(
                            getDefaultRoute('customer.detail', {
                              params: {
                                id: customer.customer,
                              },
                            })
                          )
                        "
                      >
                        <v-icon class="color-custom-blue"
                          >mdi-link mdi-rotate-135</v-icon
                        >
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                  <span>Attention</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      v-if="contactPerson"
                      class="py-1 max-content-width"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-list-item-icon class="margin-auto-zero mr-4">
                        <v-icon class="font-size-28">mdi-phone</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="p-0">
                        <v-list-item-title
                          class="custom-nowrap-ellipsis text-lowercase font-size-18 font-weight-500"
                        >
                          <a :href="'tel:' + contactPerson.primary_phone">{{
                            contactPerson.primary_phone
                          }}</a>
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>Contact Phone</span>
                </v-tooltip>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item class="py-1" v-bind="attrs" v-on="on">
                      <v-list-item-icon class="margin-auto-zero mr-4">
                        <v-icon class="font-size-28"
                          >mdi-home-map-marker</v-icon
                        >
                      </v-list-item-icon>
                      <v-list-item-content class="p-0">
                        <v-list-item-title
                          class="custom-nowrap-ellipsis-two-line font-size-18 font-weight-500"
                        >
                          <a
                            target="_blank"
                            :href="
                              'https://maps.google.com/?q=' + PropertyDetail
                            "
                            >{{ PropertyDetail }}</a
                          >
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <span>Property Address</span>
                </v-tooltip>
              </v-col>
              <v-col>
                <v-tabs
                  v-model="timeTrackingTab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent tab-sticky"
                >
                  <v-tab
                    class="font-size-16 px-8 font-weight-600 color-custom-blue"
                    :href="'#tab-detail'"
                  >
                    <v-icon color="grey darken-4" left></v-icon> Detail
                  </v-tab>
                  <v-tab
                    class="font-size-16 px-8 font-weight-600 color-custom-blue"
                    :href="'#tab-remarks'"
                  >
                    <v-icon color="grey darken-4" left></v-icon> Remarks
                  </v-tab>
                  <v-tab
                    v-if="$haveRecordAudio"
                    class="font-size-16 px-8 font-weight-600 color-custom-blue"
                    :href="'#tab-audio'"
                  >
                    <v-icon color="grey darken-4" left></v-icon> Audio
                  </v-tab>
                  <v-tabs-items
                    class="timetracking-tab"
                    v-model="timeTrackingTab"
                  >
                    <v-tab-item :value="'tab-detail'">
                      <div class="pt-2">
                        <read-more
                          v-if="timesheetDetail.details"
                          class="custom-read-more mt-2 font-weight-600 font-size-16 custom-gray-color"
                          more-str="read more"
                          :text="timesheetDetail.details || ''"
                          link="#"
                          less-str="read less"
                          :max-chars="300"
                        >
                        </read-more>
                        <em v-else class="font-weight-600 font-size-16"
                          >No Details...</em
                        >
                      </div>
                    </v-tab-item>
                    <v-tab-item :value="'tab-remarks'">
                      <div class="pt-2">
                        <v-list
                          v-if="
                            lodash.isEmpty(timesheetDetail.stop_remarks) ==
                            false
                          "
                        >
                          <template
                            v-for="(
                              item, index
                            ) in timesheetDetail.stop_remarks"
                          >
                            <v-list-item
                              :key="index"
                              :class="{ 'custom-border-top': index > 0 }"
                            >
                              <v-list-item-content>
                                <read-more
                                  class="custom-read-more mt-2 font-weight-600 font-size-16 custom-gray-color custom-border-right"
                                  more-str="read more"
                                  :text="item.detail || ''"
                                  link="#"
                                  less-str="read less"
                                  :max-chars="300"
                                >
                                </read-more>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-list-item-title
                                  class="font-weight-600 font-size-16"
                                  >{{
                                    formatDateTime(item.added_on)
                                  }}</v-list-item-title
                                >
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                        <em v-else class="font-weight-600 font-size-16"
                          >No Remarks...</em
                        >
                      </div>
                    </v-tab-item>
                    <v-tab-item v-if="$haveRecordAudio" :value="'tab-audio'">
                      <div class="pt-2">
                        <v-list
                          v-if="lodash.isEmpty(timesheetDetail.audio) == false"
                        >
                          <template
                            v-for="(item, index) in timesheetDetail.audio"
                          >
                            <v-list-item
                              :key="index"
                              :class="{ 'custom-border-top': index > 0 }"
                            >
                              <v-list-item-content
                                class="px-4 custom-border-right"
                              >
                                <audio
                                  controls
                                  :src="item.audio"
                                  type="audio/mp3"
                                  class="width-100"
                                  preload="none"
                                />
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-list-item-title
                                  class="font-weight-600 font-size-16"
                                  >{{
                                    formatDateTime(item.added_at)
                                  }}</v-list-item-title
                                >
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                        <em v-else class="font-weight-600 font-size-16"
                          >No Audio...</em
                        >
                      </div>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="timerDetailDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>
    </template>
    <template v-if="getPermission('visit:update')">
      <Dialog :commonDialog="forceStopTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue">
                  Are you sure, you want to stop selected timers of visit#
                  {{ visitDetail.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="formLoading || pageLoading"
            :loading="formLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="stopAllTimers"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="formLoading || pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="forceStopTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import CreateTimeTracking from "@/view/pages/job/partials/Create-Time-Tracking.vue";
import CreateTimeCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { CreateCloserEventBus } from "@/core/lib/ticket-closer/create.ticket.closer.lib";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { PATCH } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, JobRequestMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
    isVisitDetail: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CreateTimeCloser,
    CreateTimeTracking,
    Barcode,
    Dialog,
  },
  data() {
    return {
      totalTimeString: null,
      timeTrackingTab: null,
      timeTrackingDialog: false,
      forceStopTimerDialog: false,
      timerDetailDialog: false,
      closerDialog: false,
      visit: 0,
      job: 0,
      updateData: {},
      timeTrackings: [],
      selectedRows: [],
      timesheets: [],
      engineerList: [],
      dates: [],
      visitList: [],
      filter: {
        search: null,
        engineer: 0,
        visit: 0,
      },
      timeout: null,
      timeoutLimit: 500,
      timesheetLoading: false,
      timesheetDetail: new Object(),
    };
  },
  watch: {
    visitDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.filter.visit = this.lodash.toSafeInteger(param.id);
      },
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.job = this.lodash.toSafeInteger(param.id);
        this.getTimeTracking();
        this.getEngineerList();
        this.getVisitList();
      },
    },
  },
  methods: {
    stopAllTimers() {
      const _this = this;
      _this.formLoading = true;

      if (
        _this.lodash.isEmpty(_this.selectedRows) === false &&
        _this.lodash.isArray(_this.selectedRows)
      ) {
        _this.$store
          .dispatch(PATCH, {
            url:
              "visit/" +
              _this.visitDetail.id +
              "/stop-all-timer/" +
              _this.selectedRows,
          })
          .then(() => {
            _this.createdTimeCloser();
            _this.forceStopTimerDialog = false;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.formLoading = false;
          });
      }
    },
    createTimeTracking() {
      this.timeTrackingDialog = true;
    },
    closeTrackingDialog() {
      this.timeTrackingDialog = false;
      this.updateData = new Object();
    },
    createTicketCloser() {
      const _this = this;
      let visitArray = [];
      _this.timesheets = [];
      for (let i = 0; i < _this.selectedRows.length; i++) {
        let index = _this.lodash.findIndex(_this.timeTrackings, (row) => {
          return (
            _this.lodash.toSafeInteger(row.id) ===
            _this.lodash.toSafeInteger(_this.selectedRows[i])
          );
        });
        if (_this.lodash.isEmpty(_this.timeTrackings[index]) === false) {
          if (_this.lodash.isEmpty(_this.timeTrackings[index].closer)) {
            _this.timesheets.push(_this.timeTrackings[index].id);
            if (
              _this.lodash.isEmpty(_this.timeTrackings[index].visit) === false
            ) {
              visitArray.push(_this.timeTrackings[index].visit.id);
            }
          }
        }
      }

      if (_this.lodash.uniq(visitArray).length > 1) {
        _this.timesheets = [];
        _this.visit = 0;
        _this.closerDialog = false;
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Timesheet must be of single visit. Please try again."
          )
        );
      } else {
        _this.closerDialog = true;
        _this.visit = _this.lodash.head(visitArray);
      }
    },
    createdTimeCloser() {
      this.closerDialog = false;
      this.selectedRows = [];
      this.getTimeTracking();
    },
    updateTimeTracking(row) {
      this.timerDetailDialog = true;
      this.timeTrackingTab = null;
      this.timesheetDetail = row;
    },
    /*updateTimeTracking(row) {
      this.timeTrackingDialog = true;
      this.updateData = row;
    },*/
    updatedTimeTracking() {
      this.timeTrackingDialog = false;
      this.updateData = {};
      this.$emit("update:service-form", true);
      this.getTimeTracking();
    },
    getTimeTracking() {
      const _this = this;
      let filter = {
        search: _this.filter.search,
        visit: _this.lodash.toSafeInteger(_this.filter.visit),
        engineer: _this.lodash.toSafeInteger(_this.filter.engineer),
        daterange: _this.dates,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }
      clearTimeout(_this.timeout);
      _this.timesheetLoading = true;
      _this.timeout = setTimeout(function () {
        _this
          .getTimesheets(_this.job, { filter })
          .then((response) => {
            _this.timeTrackings = response;
            _this.calculateTotalDuration(response);
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.timesheetLoading = false;
          });
      }, _this.timeoutLimit);
    },
    calculateTotalDuration(response) {
      const _this = this;
      if (
        _this.lodash.isEmpty(response) === false &&
        _this.lodash.isArray(response)
      ) {
        let totalDuration = null;
        for (let i = 0; i < response.length; i++) {
          totalDuration = moment
            .duration(totalDuration)
            .add(response[i].duration, "minutes");
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        _this.totalTimeString = result.join(" ");
      }
    },
    getEngineerList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return true;
      }
      _this
        .getEngineers(_this.job)
        .then(({ rows }) => {
          if (_this.lodash.isEmpty(rows) === false) {
            _this.engineerList = rows.map((row) => {
              return row.engineer;
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getVisitList() {
      const _this = this;
      if (_this.isVisitDetail) {
        return true;
      }
      _this
        .getVisitsForFilter(_this.job)
        .then((response) => {
          _this.visitList = response;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        for (let i = 0; i < param.length; i++) {
          if (_this.lodash.isEmpty(param[i].engineer) === false) {
            display_name.push(param[i].engineer.display_name);
          }
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned yet.";
      } else {
        return "Assigned to " + display_name.join(", ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getTimeTracking();
    },
  },
  mounted() {
    this.getTimeTracking();
    this.getEngineerList();
    this.getVisitList();
  },
  beforeDestroy() {
    /*CreateCloserEventBus.$off("create:time-closer");*/
  },
  created() {
    const _this = this;

    CreateCloserEventBus.$on("create:time-closer", function () {
      _this.createdTimeCloser();
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
    PropertyDetail() {
      let propertyArr = new Array();
      if (this.lodash.isEmpty(this.property) === false) {
        if (this.property.unit_no) {
          propertyArr.push(this.property.unit_no);
        }
        if (this.property.street_1) {
          propertyArr.push(this.property.street_1);
        }
        if (this.property.street_2) {
          propertyArr.push(this.property.street_2);
        }
        if (this.property.zip_code) {
          propertyArr.push(this.property.zip_code);
        }
        if (this.CityName) {
          propertyArr.push(this.CityName);
        }
        if (this.StateName) {
          propertyArr.push(this.StateName);
        }
        if (this.CountryName) {
          propertyArr.push(this.CountryName);
        }
      }
      return propertyArr.join(", ");
    },
    CityName() {
      return this.lodash.isEmpty(this.property.city) === false
        ? this.property.city.name
        : null;
    },
    StateName() {
      return this.lodash.isEmpty(this.property.state) === false
        ? this.property.state.name
        : null;
    },
    CountryName() {
      return this.lodash.isEmpty(this.property.country) === false
        ? this.property.country.name
        : null;
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.timeTrackings.length) {
          for (let i = this.timeTrackings.length - 1; i >= 0; i--) {
            if (this.lodash.isEmpty(this.timeTrackings[i].closer)) {
              this.selectedRows.push(this.timeTrackings[i].id);
            }
          }
        }
      },
      get() {
        const _this = this;
        let totalItems = _this.lodash.compact(
          _this.timeTrackings.map((row) => {
            return _this.lodash.isEmpty(row.closer) ? row : undefined;
          })
        ).length;
        return totalItems > 0
          ? _this.selectedRows.length === totalItems
          : false;
      },
    },
  },
};
</script>
