<template>
  <Dialog :commonDialog="detailDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <v-row>
        <v-col md="8">
          <p class="m-0 custom-nowrap-ellipsis">
            {{ serviceFormInfo.title }}
            <v-icon small class="mx-4 color-custom-blue"
              >mdi-checkbox-blank-circle</v-icon
            >
            #{{ serviceFormInfo.barcode }}
          </p>
        </v-col>
        <v-col md="4">
          <v-layout class="justify-end">
            <template
              v-if="
                serviceFormInfo.can_update &&
                getPermission('service-form:update')
              "
            >
              <v-btn
                v-if="!serviceFormInfo.is_filled"
                :loading="formLoading"
                :disabled="formLoading"
                v-on:click="saveServiceForm('save')"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Save
              </v-btn>
              <v-btn
                v-if="!serviceFormInfo.is_filled"
                :loading="formLoading"
                :disabled="formLoading"
                v-on:click="saveServiceForm('submit')"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
              >
                Submit
              </v-btn>
            </template>
            <v-menu
              transition="slide-y-transition"
              bottom
              content-class="custom-menu-list"
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="formLoading || !formValid"
                  class="mx-2 custom-bold-button white--text"
                  color="cyan"
                  v-bind="attrs"
                  v-on="on"
                >
                  More... <v-icon right>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template>
                  <v-list-item
                    v-if="serviceFormInfo.is_filled"
                    v-on:click="downloadServiceForm"
                    link
                  >
                    <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                      <v-icon class="icon-default-blue">mdi-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="font-weight-500 font-size-14"
                      >Download</v-list-item-title
                    >
                  </v-list-item>
                  <template v-if="getPermission('service-form:delete')">
                    <v-list-item
                      v-if="!serviceFormInfo.is_filled"
                      v-on:click="deleteServiceForm"
                      link
                    >
                      <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                        <v-icon class="icon-default-blue">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title class="font-weight-500 font-size-14"
                        >Delete</v-list-item-title
                      >
                    </v-list-item>
                  </template>
                </template>
              </v-list>
            </v-menu>
            <v-btn
              v-on:click="$emit('close:dialog')"
              :disabled="formLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              >Close
            </v-btn>
          </v-layout>
        </v-col>
      </v-row>
    </template>
    <template v-slot:body>
      <v-form
        ref="serviceDataForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="saveServiceForm('')"
      >
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 65vh; position: relative"
        >
          <ServiceFormTemplate
            :serviceForm="serviceFormInfo"
            :detail="detail"
            :customer="customer"
            :property="property"
            :billing="billing"
            :contactPerson="contactPerson"
          ></ServiceFormTemplate>
        </perfect-scrollbar>
      </v-form>
      <template v-if="getPermission('service-form:delete')">
        <ServiceFormDelete
          :deleteDialog="ServiceFormDeleteDialog"
          :requestUrl="ServiceFormDeleteRequestURL"
          v-on:delete:success="ServiceFormDeleteSuccess"
          v-on:delete:close="ServiceFormDeleteDialog = false"
        ></ServiceFormDelete>
      </template>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import ServiceFormTemplate from "@/view/pages/service-form/Service-Form-Template.vue";
import ServiceFormDelete from "@/view/pages/partials/Delete-Request-Template.vue";
import { GET, DOWNLOAD } from "@/core/services/store/request.module";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { saveAs } from "file-saver";

export default {
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    customer: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    property: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    billing: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    contactPerson: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      type: Number,
      required: true,
      default: 0,
    },
    detailDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      attributes: [],
      serviceFormInfo: new Object(),
      formLoading: false,
      formValid: true,
      ServiceFormDeleteDialog: false,
      ServiceFormDeleteRequestURL: "",
    };
  },
  methods: {
    ServiceFormDeleteSuccess() {
      this.ServiceFormDeleteDialog = false;
      this.ServiceFormDeleteRequestURL = "";
      this.$emit("close:dialog");
    },
    downloadServiceForm() {
      const _this = this;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: `job/${_this.serviceFormInfo.ticket}/service-forms/${_this.serviceFormInfo.id}/download`,
        })
        .then(({ data }) => {
          saveAs(data, _this.serviceFormInfo.title + ".pdf");
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    saveServiceForm(param) {
      ServiceFormEventBus.$emit("start:save-service-form", param);
    },
    loadContent() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: `job/${_this.detail.id}/service-forms/${_this.serviceForm}`,
        })
        .then(({ data }) => {
          _this.serviceFormInfo = data;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    deleteServiceForm() {
      this.ServiceFormDeleteDialog = true;
      this.ServiceFormDeleteRequestURL =
        "job/" +
        this.detail.id +
        "/visit/" +
        this.serviceFormInfo.visit.id +
        "/service-form/" +
        this.serviceFormInfo.id;
    },
  },
  components: {
    Dialog,
    ServiceFormTemplate,
    ServiceFormDelete,
  },
  mounted() {
    this.loadContent();
  },
  beforeDestroy() {
    /*ServiceFormEventBus.$off("save:service-form");
    ServiceFormEventBus.$off("close:loader");
    ServiceFormEventBus.$off("start:loader");
    ServiceFormEventBus.$off("update:service-form-attributes");*/
  },
  created() {
    const _this = this;

    ServiceFormEventBus.$on("save:service-form", function (param) {
      _this.serviceFormInfo = param;
    });
    ServiceFormEventBus.$on("close:loader", function () {
      _this.formLoading = false;
    });
    ServiceFormEventBus.$on("start:loader", function () {
      _this.formLoading = true;
    });

    ServiceFormEventBus.$on(
      "update:service-form-attributes",
      function (argument) {
        _this.attributes = argument;
      }
    );
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 80);
    },
  },
};
</script>
