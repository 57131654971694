<template>
  <div :class="{ 'my-1': small }">
    <v-tooltip top content-class="custom-top-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          class="my-auto font-weight-600"
          :color="priorityColor"
          outlined
          label
          :small="small"
          :class="{
            'd-inline mx-2': !visitList && small,
            'ml-4 mr-2': !visitList && !small,
          }"
        >
          {{ priorityText }}
        </v-chip>
      </template>
      <span>Priority</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    visitList: {
      type: Boolean,
      default: false,
    },
    priority: {
      type: Number,
      required: true,
    },
  },
  computed: {
    priorityText() {
      if (this.priority == 1) {
        return "High";
      }
      if (this.priority == 2) {
        return "Medium";
      }
      if (this.priority == 3) {
        return "Low";
      }
      if (this.priority == 0) {
        return "None";
      }
      return null;
    },
    priorityColor() {
      if (this.priority == 1) {
        return "red darken-3";
      }
      if (this.priority == 2) {
        return "orange darken-4";
      }
      if (this.priority == 3) {
        return "light-green darken-3";
      }
      if (this.priority == 0) {
        return "cyan";
      }
      return null;
    },
  },
};
</script>
