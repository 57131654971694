<template>
  <transition name="fade-in-up">
    <v-container
      fluid
      class="service-form-template custom-border-grey-dashed"
      id="service-form"
      :class="{
        'pointer-events-none': !getPermission('service-form:update'),
      }"
    >
      <component
        :is="getComponent"
        :detail="detail"
        :serviceForm="serviceForm"
      ></component>
    </v-container>
  </transition>
</template>

<script>
import ServiceReportServiceForm from "@/view/pages/service-form/genic-teams/Service-Report.vue";
import WorkOrderServiceForm from "@/view/pages/service-form/genic-teams/Work-Order.vue";

export default {
  components: {
    ServiceReportServiceForm,
    WorkOrderServiceForm,
  },
  props: {
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    serviceForm: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  computed: {
    getComponent() {
      let component = null;
      if (this.lodash.isEmpty(this.serviceForm) === false) {
        switch (this.lodash.toLower(this.serviceForm.service_form)) {
          case "service-report": {
            component = ServiceReportServiceForm;
            break;
          }
          case "work-order": {
            component = WorkOrderServiceForm;
            break;
          }
        }
      }
      return component;
    },
  },
};
</script>
