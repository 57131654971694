import { QUERY, PATCH } from "@/core/services/store/request.module";

export default {
  methods: {
    getJob(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getLineItems(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/line-items",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getEngineers(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/engineers",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getRecurrence(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/recurrence",
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getDocuments(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/documents",
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getVisits(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/visits",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getVisitsForFilter(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/visits/filter",
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getInvoiceReminders(job) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/invoice-reminders",
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getServiceForms(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/service-forms",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getTimesheets(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/timesheets",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getClosers(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(QUERY, {
                url: "job/" + job + "/closers",
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    updateStatus(job, data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (job) {
            _this.$store
              .dispatch(PATCH, {
                url: "job/" + job,
                data,
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          } else {
            reject("Job is required");
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
