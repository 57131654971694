import moment from "moment-timezone";
import AppConfiguration from "@/core/config/app.config";
import CommonMixin from "@/core/plugins/common-mixin";
import { POST, PUT } from "@/core/services/store/request.module";
import Dialog from "@/view/pages/partials/Dialog.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin, ValidationMixin],
  props: {
    VisitDialog: {
      type: Boolean,
      required: true,
      default: false,
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      },
    },
    update: {
      type: Object,
      default() {
        return new Object();
      },
    },
    visitDetail: {
      type: Object,
      default() {
        return new Object();
      },
    },
  },
  watch: {
    visitDetail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.visit = this.lodash.toSafeInteger(param.id);
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      datePicker: false,
      rawStartTime: null,
      startTimePicker: false,
      rawEndTime: null,
      endTimePicker: false,
      endDatePicker: false,
      visit: 0,
      data: {
        instruction: null,
        start_at: null,
        end_at: null,
        date: null,
        end_date: null,
      },
    };
  },
  methods: {
    updateStartTimeAMPM(param) {
      let startTime = this.lodash.toString(this.data.start_at);
      if (startTime) {
        if (param == "am") {
          startTime = this.lodash.replace(startTime, "PM", "AM");
        } else {
          startTime = this.lodash.replace(startTime, "AM", "PM");
        }
        this.updateStartTime(moment(startTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateEndTimeAMPM(param) {
      let endTime = this.lodash.toString(this.data.end_at);
      if (endTime) {
        if (param == "am") {
          endTime = this.lodash.replace(endTime, "PM", "AM");
        } else {
          endTime = this.lodash.replace(endTime, "AM", "PM");
        }
        this.updateEndTime(moment(endTime, ["hh:mm A"]).format("HH:mm"));
      }
    },
    updateStartTime(rawTime) {
      this.$refs.rawStartTime.save(rawTime);
      this.data.start_at = moment(rawTime, ["HH:mm"]).format("hh:mm A");
    },
    updateEndTime(rawTime) {
      this.$refs.rawEndTime.save(rawTime);
      this.data.end_at = moment(rawTime, ["HH:mm"]).format("hh:mm A");
    },
    updateOrCreate() {
      const _this = this;

      if (!_this.$refs.timeTrackingForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      const formData = {
        title: _this.data.title,
        instruction: _this.data.instruction,
        start_date: _this.data.date,
        start_time: moment(_this.data.start_at, "hh:mm A").format("HH:mm"),
        end_date: _this.data.end_date,
        end_time: moment(_this.data.end_at, "hh:mm A").format("HH:mm"),
      };

      let requestType = POST;
      let requestURL =
        "job/" + _this.detail.id + "/visit/" + _this.visit + "/reminder";

      if (_this.lodash.isEmpty(_this.update) === false) {
        requestType = PUT;
        requestURL =
          "job/" +
          _this.detail.id +
          "/visit/" +
          _this.visit +
          "/reminder/" +
          _this.update.id;
      }

      _this.$store
        .dispatch(requestType, {
          url: requestURL,
          data: formData,
        })
        .then(() => {
          _this.$emit("create:visit-reminder", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
  },
  computed: {
    formattedDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.data.date).format(Config.dateFormat);
      }
      return this.data.date;
    },
    formattedEndDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        return moment(this.data.end_date).format(Config.dateFormat);
      }
      return this.data.end_date;
    },
  },
  mounted() {
    const _this = this;
    _this.data = new Object({
      title: null,
      instruction: null,
      start_at: moment().startOf("day").format("hh:mm A"),
      end_at: moment().endOf("day").format("hh:mm A"),
      end_date: new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),
    });
    if (_this.lodash.isEmpty(_this.update) === false) {
      _this.data.title = _this.update.title;
      _this.data.instruction = _this.update.instruction;
      _this.data.start_at = moment(_this.update.started_at).format("hh:mm A");
      _this.data.end_at = moment(_this.update.finished_at).format("hh:mm A");
      _this.data.date = moment(_this.update.started_at).format("YYYY-MM-DD");
      _this.data.end_date = moment(_this.update.finished_at).format(
        "YYYY-MM-DD"
      );
    }
  },
  components: {
    Dialog,
  },
};
